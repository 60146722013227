import React from "react";
import PropTypes from "prop-types";

import { googleMapsURL } from "lib/utils";

import VolunteeringDetailDate from "./VolunteeringDetailDate";
import {
  SHIFT_TRAINING_TYPES_WITH_VISIBLE_LOCATION,
  VOLUNTEER_TYPES,
} from "./constants";

const VolunteeringDetailItem = ({
  addEventToCalendarActionRequired,
  viewMapActionRequired,
  item,
  openCancelShiftModalFn,
  volunteeringDetailType,
}) => {
  return (
    <div className="volunteering-detail-item">
      <VolunteeringDetailItemHeader
        volunteeringDetailType={volunteeringDetailType}
        item={item}
        addEventToCalendarActionRequired={addEventToCalendarActionRequired}
        viewMapActionRequired={viewMapActionRequired}
      />
      <VolunteeringDetailItemContent
        item={item}
        volunteeringDetailType={volunteeringDetailType}
        addEventToCalendarActionRequired={addEventToCalendarActionRequired}
        openCancelShiftModalFn={openCancelShiftModalFn}
      />
    </div>
  );
};

const FieldProgramItemInfo = ({ item }) => {
  const { name, locationName, address } = item;
  const { fullAddress1, fullAddress2 } = address;

  return (
    <div className="volunteering-detail-item__info">
      <h4 className="volunteering-detail-item__heading">
        {name || locationName}
      </h4>
      <small className="volunteering-detail-item__address">
        {fullAddress1}
      </small>
      <small className="volunteering-detail-item__details">
        {fullAddress2}
      </small>
    </div>
  );
};

const OnDemandItemInfo = () => {
  return (
    <div className="volunteering-detail-item__info">
      <h4 className="volunteering-detail-item__heading">On-Demand</h4>
      <small className="volunteering-detail-item__on-demand-detail">
        Training video(s) will be sent to your email
      </small>
    </div>
  );
};

const LiveOnlineWebinarItemInfo = ({ item }) => {
  const { title, description } = item;

  return (
    <div className="volunteering-detail-item__info">
      <h4 className="volunteering-detail-item__heading">{title}</h4>
      <small className="volunteering-detail-item__address">{description}</small>
    </div>
  );
};

const StandardItemInfo = ({ item }) => {
  const { name, locationName, address, remote: isRemote } = item;
  const { fullAddress1, fullAddress2 } = address;

  return (
    <div className="volunteering-detail-item__info">
      <h4 className="volunteering-detail-item__heading">
        {name || locationName}
      </h4>
      {!isRemote && (
        <>
          <small className="volunteering-detail-item__address">
            {fullAddress1}
          </small>
          <small className="volunteering-detail-item__details">
            {fullAddress2}
          </small>
        </>
      )}
      {isRemote && (
        <>
          <small className="volunteering-detail-item__details">
            Remote Opportunity
          </small>
        </>
      )}
    </div>
  );
};

const VolunteeringDetailItemInfo = ({ item }) => {
  const { type } = item;
  const itemInfoComponents = {
    LiveOnlineWebinar: LiveOnlineWebinarItemInfo,
    OnDemandVideo: OnDemandItemInfo,
    FieldProgram: FieldProgramItemInfo,
  };
  const ItemInfoComponent = itemInfoComponents[type] || StandardItemInfo;
  return <ItemInfoComponent item={item} />;
};

const VolunteeringDetailItemExtraInfo = ({
  item: { attributes },
  viewMapActionRequired,
}) => {
  const { type, opportunityType, address, remote: isRemote } = attributes;
  const hasVisibleLocation = SHIFT_TRAINING_TYPES_WITH_VISIBLE_LOCATION.includes(
    type,
  );
  const showViewMapLink =
    viewMapActionRequired && hasVisibleLocation && !isRemote;

  return (
    <div className="volunteering-detail-item__extra-info">
      <small className="volunteering-detail-item__type">
        {VOLUNTEER_TYPES[opportunityType || type]}
      </small>
      {showViewMapLink && (
        <a
          className="volunteering-detail-item__map-link"
          href={googleMapsURL(address.location)}
          rel="noopener noreferrer"
          target="_blank"
        >
          View Map
        </a>
      )}
    </div>
  );
};

const VolunteeringDetailItemHeader = ({
  item,
  addEventToCalendarActionRequired,
  volunteeringDetailType,
  viewMapActionRequired,
}) => {
  return (
    <div className="volunteering-detail-item__header">
      <VolunteeringDetailItemInfo
        item={item.attributes}
        volunteeringDetailType={volunteeringDetailType}
      />
      <VolunteeringDetailItemExtraInfo
        item={item}
        volunteeringDetailType={volunteeringDetailType}
        addEventToCalendarActionRequired={addEventToCalendarActionRequired}
        viewMapActionRequired={viewMapActionRequired}
      />
    </div>
  );
};

const VolunteeringDetailItemContent = ({
  item,
  volunteeringDetailType,
  addEventToCalendarActionRequired,
  openCancelShiftModalFn,
}) => {
  return (
    <div className="volunteering-detail-item__content">
      {item.attributes.shifts.map(selectedDate => {
        return (
          item.attributes.type !== "OnDemandVideo" && (
            <VolunteeringDetailDate
              addEventToCalendarActionRequired={
                addEventToCalendarActionRequired
              }
              item={item}
              key={`volunteering-detail-item-content-${selectedDate.id}`}
              openCancelShiftModalFn={openCancelShiftModalFn}
              selectedDate={selectedDate}
              volunteeringDetailType={volunteeringDetailType}
            />
          )
        );
      })}
    </div>
  );
};

export default VolunteeringDetailItem;

VolunteeringDetailItemInfo.propTypes = {
  item: PropTypes.object.isRequired,
  volunteeringDetailType: PropTypes.string,
};

VolunteeringDetailItemExtraInfo.propTypes = {
  item: PropTypes.object.isRequired,
  addEventToCalendarActionRequired: PropTypes.bool,
};

VolunteeringDetailItemHeader.propTypes = {
  item: PropTypes.object.isRequired,
  addEventToCalendarActionRequired: PropTypes.bool,
};

VolunteeringDetailItem.propTypes = {
  item: PropTypes.object.isRequired,
  volunteeringDetailType: PropTypes.string.isRequired,
  addEventToCalendarActionRequired: PropTypes.bool,
  openCancelShiftModalFn: PropTypes.func,
  viewMapActionRequired: PropTypes.bool,
};

VolunteeringDetailItemContent.propTypes = {
  item: PropTypes.object.isRequired,
  volunteeringDetailType: PropTypes.string.isRequired,
  addEventToCalendarActionRequired: PropTypes.bool,
  openCancelShiftModalFn: PropTypes.func,
};
