import React from "react";
import ReactDOM from "react-dom";

import VolunteerShiftsSelect from "components/VolunteerShiftsSelect";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.querySelector("#root_select_shifts");
  if (node) {
    const callCenters = JSON.parse(node.dataset.callCenters).data;
    const fieldPrograms = JSON.parse(node.dataset.fieldPrograms).data;
    const booking = JSON.parse(node.dataset.booking);

    ReactDOM.render(
      <VolunteerShiftsSelect
        booking={{ ...booking.data.attributes, links: booking.links }}
        callCenters={callCenters}
        fieldPrograms={fieldPrograms}
      />,
      node,
    );
  }
});
