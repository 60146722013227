import React from "react";
import PropTypes from "prop-types";
import { googleMapsURL } from "lib/utils";
import ConfirmShiftsSlots from "./ConfirmShiftsSlots";

const volunteerTypes = {
  CallCenter: "Call Center",
  FieldProgram: "Field Program",
};

const ConfirmShiftsLocation = ({
  location,
  selectedShifts,
  updateSelectedShiftsFn,
}) => {
  return (
    <div className="confirm-shifts-location">
      <ConfirmShiftsLocationHeader location={location} />

      <ConfirmShiftsLocationContent
        location={location}
        selectedShifts={selectedShifts}
        updateSelectedShiftsFn={updateSelectedShiftsFn}
      />
    </div>
  );
};

const AddressInfo = ({ location }) => {
  const fullAddress1 = location.address.fullAddress1;
  return (
    <>
      <small className="confirm-shifts-location__address">{fullAddress1}</small>
      <small className="confirm-shifts-location__details">
        {location.address.city}, {location.address.stateCode}{" "}
        {location.address.zipCode}
      </small>
    </>
  );
};

const ConfirmShiftsLocationHeader = ({ location }) => {
  const isCallCenter = location.type === "CallCenter";
  const isRemote = location.remote;

  return (
    <div className="confirm-shifts-location__header">
      <div className="confirm-shifts-location__info">
        <h4 className="confirm-shifts-location__heading">{location.name}</h4>
        {isCallCenter && !isRemote && <AddressInfo location={location} />}
        {isCallCenter && isRemote && (
          <span className="confirm-shifts-location__details">
            Remote Opportunity
          </span>
        )}
      </div>
      <div className="confirm-shifts-location__extra-info">
        <small className="confirm-shifts-location__type">
          {volunteerTypes[location.type]}
        </small>
        {isCallCenter && !isRemote && (
          <a
            className="confirm-shifts-location__map-link"
            href={googleMapsURL(location.address.location)}
            rel="noopener noreferrer"
            target="_blank"
          >
            View Map
          </a>
        )}
      </div>
    </div>
  );
};

const ConfirmShiftsLocationContent = ({
  location,
  selectedShifts,
  updateSelectedShiftsFn,
}) => {
  const regularShifts =
    location.type === "FieldProgram"
      ? location.shifts
      : location.volunteerShifts;

  return (
    <div className="confirm-shifts-location__content">
      {regularShifts.map(({ availableTimes, date }) => (
        <ConfirmShiftsSlots
          availableDate={date}
          availableTimes={availableTimes}
          key={`${date}`}
          selectedShifts={selectedShifts}
          updateSelectedShiftsFn={updateSelectedShiftsFn}
        />
      ))}

      {!!location.captainShifts &&
        location.captainShifts.map(({ availableTimes, date }) => (
          <ConfirmShiftsSlots
            availableDate={date}
            availableTimes={availableTimes}
            key={`${date}-captain-shifts`}
            selectedShifts={selectedShifts}
            updateSelectedShiftsFn={updateSelectedShiftsFn}
          />
        ))}
    </div>
  );
};

ConfirmShiftsLocation.propTypes = {
  location: PropTypes.object.isRequired,
  selectedShifts: PropTypes.array.isRequired,
  updateSelectedShiftsFn: PropTypes.func.isRequired,
};

ConfirmShiftsLocationContent.propTypes = {
  location: PropTypes.object.isRequired,
  selectedShifts: PropTypes.array.isRequired,
  updateSelectedShiftsFn: PropTypes.func.isRequired,
};

ConfirmShiftsLocationHeader.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ConfirmShiftsLocation;
