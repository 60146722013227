import React, { useState } from "react";
import ReactDOM from "react-dom";
import SelectWithHiddenField from "lib/forms/SelectWithHiddenField";
import CSRFToken from "lib/forms/CSRFToken";
import { zipCodeValidation } from "../../CompleteProfileForm/volunteerProfileSchema";

function SignupForm({ values, options, zipCode: zip }) {
  const [setLegalType] = useState(values);
  const [zipCode, setZipCode] = useState(zip);

  let error = "";
  if (!zipCode.match(zipCodeValidation)) {
    error = "Zip Code is invalid";
  }
  if (zipCode === "") {
    error = "Zip Code can't be blank";
  }

  const hasErrors = error !== "";
  return (
    <div className="form-wrapper">
      <p className="shifts-search__note">
        Find volunteer opportunities near you:
      </p>
      <form
        className="signup-form"
        id="new_booking"
        action="/volunteering"
        accept-charset="UTF-8"
        method="post"
      >
        <CSRFToken />

        <div className={`signup-form__input-group ${error ? "error" : null}`}>
          <input
            className="input-text signup-form__input"
            placeholder="Zip Code"
            type="number"
            value={zipCode}
            onChange={e => setZipCode(e.target.value)}
            name="booking[zip_code]"
            id="booking_zip_code"
          />
          {error && <div className="error-message">{error}</div>}
        </div>
        <div className="signup-form__legal-experience">
          <label className="signup-form__legal-experience__label">
            What is your legal experience?
            <div className="tooltip tooltip-trigger" tabindex="0">
              <div className="tooltip-content">
                We offer volunteer opportunities for those with legal expertise
                and without.
              </div>
            </div>
          </label>
          <SelectWithHiddenField
            defaultValues={values}
            options={options}
            clearable={false}
            onChange={legalType => setLegalType(legalType)}
            name="booking[legal_type]"
          />
        </div>
        <input
          type="submit"
          name="commit"
          disabled={hasErrors}
          value="Search for shifts"
          className="signup-form__button green-button"
        />
      </form>
    </div>
  );
}

export const initReactSelect = () => {
  document.querySelectorAll("#signup_form").forEach(el => {
    const options = JSON.parse(el.dataset.options);
    const values = JSON.parse(el.dataset.values);
    const zipCode = el.dataset.zipCode;

    ReactDOM.render(
      <SignupForm options={options} values={values} zipCode={zipCode} />,
      el,
    );
  });
};
