import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { timeRangeToString } from "lib/utils";
import { getTimeZoneAbbr } from "lib/constants";

class AvailableTimeSlot extends Component {
  updateSelectedSlot = () => {
    const {
      availableTime,
      updateSelectedSlotFn,
      seatsAvailabilityCheckRequired,
    } = this.props;

    if (seatsAvailabilityCheckRequired && availableTime.seats === 0) {
      return false;
    }
    updateSelectedSlotFn(availableTime);
  };

  render() {
    const {
      availableTime,
      slotSelected,
      seatsAvailabilityCheckRequired,
    } = this.props;

    const timeSlotClassnames = classNames("available-time-slot", {
      "available-time-slot--selected": slotSelected,
      "available-time-slot--disabled":
        seatsAvailabilityCheckRequired && availableTime.seats === 0,
    });

    return (
      <div
        className={timeSlotClassnames}
        onClick={this.updateSelectedSlot}
        data-testid="timeslot"
      >
        <div className="available-time-slots__time">
          {timeRangeToString(
            availableTime.iso8601StartAt,
            availableTime.iso8601EndAt,
            getTimeZoneAbbr,
          )}
        </div>
        {seatsAvailabilityCheckRequired && (
          <div className="available-time-slots__slots-count">
            {availableTime.seats} slots available
          </div>
        )}
      </div>
    );
  }
}

AvailableTimeSlot.propTypes = {
  availableTime: PropTypes.object.isRequired,
  slotSelected: PropTypes.bool.isRequired,
  updateSelectedSlotFn: PropTypes.func.isRequired,
  seatsAvailabilityCheckRequired: PropTypes.bool.isRequired,
};

export default AvailableTimeSlot;
