import React, { Fragment } from 'react';
import classNames from 'classnames';

const NoTrainingSessionRequired = (props) => {
  const confirmSessionButtonClassnames = classNames(
    'button',
    'secondary-button',
    'secondary-button--large',
    'select-training-sessions-confirm',
  );

  return (
    <Fragment>
      <section className="no-training-session-required">
        <h3 className="no-training-session-required__heading">No additional training required!</h3>
        <p className="no-training-session-required__subheading">
          You are already signed up for the necessary training.
        </p>
      </section>
      <footer className="select-training-sessions-action-bar-wrapper">
        <div className="container select-training-sessions-action-bar">
          <a className={confirmSessionButtonClassnames} href={props.links.next}>
            Continue
          </a>
        </div>
      </footer>
    </Fragment>
  );
};

export default NoTrainingSessionRequired;
