window.addEventListener("DOMContentLoaded", function() {
  const navbarWrapper = document.querySelector(".main-navbar__root-wrapper");

  if (navbarWrapper) {
    navbarWrapper.addEventListener("click", function() {
      const navbar = document.querySelector(".main-navbar");
      navbar.classList.toggle("main-navbar--open");
    });
  }
});
