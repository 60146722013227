import React, { useState } from "react";
import ReactDOM from "react-dom";
import SelectWithHiddenField from "lib/forms/SelectWithHiddenField";

function LawyerExperienceSelect(props) {
  const { errors, defaultValues, hideDisclaimer } = props;
  const [legalType, setLegalType] = useState(defaultValues);

  const lawyerNotice =
    'By selecting "Lawyer" you confirm you are licensed to practice law, in good standing, in at least one state.';

  const getLegalNotice = legalType => {
    if (!legalType) return;
    const isLegalTypeLawyer = legalType && legalType.value === "Lawyer";

    if (!hideDisclaimer && isLegalTypeLawyer) {
      return <p className="signup-form__disclaimer">{lawyerNotice}</p>;
    }

    return null;
  };

  return (
    <>
      <SelectWithHiddenField
        {...props}
        onChange={legalType => setLegalType(legalType)}
      />
      {errors && (
        <div className="signup-form__legal-experience__errors">
          <div className="error-message">{errors}</div>
        </div>
      )}
      {getLegalNotice(legalType)}
    </>
  );
}

export const initReactSelect = () => {
  document.querySelectorAll("[data-react-select-input]").forEach(el => {
    const options = JSON.parse(el.dataset.reactSelectOptions);
    const values = JSON.parse(el.dataset.reactSelectValues);
    const name = el.dataset.reactSelectName;
    const clearable = el.dataset.clearable === "true";
    const hideDisclaimer = el.dataset.hideDisclaimer === "true";
    const placeholder = el.dataset.reactSelectPlaceholder;
    const errors = el.dataset.reactSelectErrors;
    const hasErrors = !!errors;

    ReactDOM.render(
      <LawyerExperienceSelect
        name={name}
        options={options}
        placeholder={placeholder}
        hideDisclaimer={hideDisclaimer}
        defaultValues={values}
        multi={!!el.dataset.reactSelectMulti}
        errors={errors}
        hasErrors={hasErrors}
        clearable={clearable}
      />,
      el,
    );
  });
};
