import { initReactSelect } from "components/Signup/LawyerExperienceSelect";

window.addEventListener("DOMContentLoaded", function() {
  const aboutEpInputWithError = document.querySelector(".home-about-ep .error");

  if (aboutEpInputWithError) {
    const aboutEpForm = document.querySelector(".home-about-ep__form");
    window.scrollTo(0, aboutEpForm.offsetTop);
  }

  initReactSelect();
});
