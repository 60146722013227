import React from "react";
import PropTypes from "prop-types";
import { withFormik, validateYupSchema, yupToFormErrors } from "formik";
import * as R from "ramda";

import { snakeize } from "lib/utils";

import volunteerProfileSchema from "./volunteerProfileSchema";
import BasicInformationForm from "./BasicInformationForm";
import LegalExperienceForm from "./LegalExperienceForm";
import PasswordForm from "./PasswordForm";
import ConfirmProfileButton from "./ConfirmProfileButton";

const shoulDisplayLegalLawyerForm = legalType =>
  legalType !== "No legal experience" && legalType !== "Other";

const valuesToOptions = list =>
  list.map(item => ({ label: item, value: item }));

const Form = ({
  areasOfInterest,
  areasOfPractice,
  errors,
  formConfig,
  handleSubmit,
  isSubmitting,
  isValid,
  languages,
  legalSkills,
  legalTypes,
  referredBy,
  setFieldTouched,
  setFieldValue,
  states,
  touched,
  values,
  volunteer,
  willingToTravel,
  yearsOfExperience,
}) => {
  const showPasswordForm =
    (formConfig.origin === "VolunteeringFlow" &&
      formConfig.context === "create") ||
    formConfig.origin === "VolunteerProfile";

  const { legalType } = volunteer.attributes.volunteerProfile;
  const currentReferredBy = valuesToOptions(
    volunteer.attributes.volunteerProfile.referredBy || [],
  );

  let referredByWithOriginalData = [...referredBy, ...currentReferredBy];
  referredByWithOriginalData = R.uniqBy(
    item => item.label,
    referredByWithOriginalData,
  );

  return (
    <form onSubmit={handleSubmit} className="complete-profile-form">
      <BasicInformationForm
        errors={errors}
        languages={languages}
        referredBy={referredByWithOriginalData}
        setFieldTouchedFn={setFieldTouched}
        setFieldValueFn={setFieldValue}
        touched={touched}
        values={values}
        willingToTravel={willingToTravel}
      />
      {shoulDisplayLegalLawyerForm(
        values.volunteer.volunteerProfile.legalType,
      ) && (
        <LegalExperienceForm
          areasOfInterest={areasOfInterest}
          areasOfPractice={areasOfPractice}
          legalType={legalType}
          errors={errors}
          legalSkills={legalSkills}
          legalTypes={legalTypes}
          legalType={legalType}
          setFieldTouchedFn={setFieldTouched}
          setFieldValueFn={setFieldValue}
          states={states}
          touched={touched}
          values={values}
          yearsOfExperience={yearsOfExperience}
        />
      )}

      {showPasswordForm && (
        <PasswordForm
          values={values}
          errors={errors}
          touched={touched}
          formConfig={formConfig}
        />
      )}

      <ConfirmProfileButton
        formConfig={formConfig}
        handleSubmitFn={handleSubmit}
        isSubmitting={isSubmitting}
        isValid={isValid}
      />
    </form>
  );
};

const CompleteProfileForm = withFormik({
  mapPropsToValues: props => ({ volunteer: props.volunteer.attributes }),
  isInitialValid: props =>
    volunteerProfileSchema.isValidSync(
      { volunteer: props.volunteer.attributes },
      { context: props.formConfig.context },
    ),
  validate: (values, props) => {
    return new Promise((resolve, reject) => {
      validateYupSchema(values, volunteerProfileSchema, {
        context: props.formConfig.context,
      }).then(
        () => resolve({}),
        err => reject(yupToFormErrors(err)),
      );
    });
  },
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    const { submitFn } = props;
    const formattedData = { volunteer: snakeize(values.volunteer) };
    submitFn(formattedData, setErrors, setSubmitting);
  },
})(Form);

CompleteProfileForm.propTypes = {
  formConfig: PropTypes.object.isRequired,
  submitFn: PropTypes.func.isRequired,
  volunteer: PropTypes.object.isRequired,
};

export default CompleteProfileForm;
