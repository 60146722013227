import React from "react";
import TrainingSessionsSelect from "components/TrainingSessionsSelect";
import NoTrainingSessionRequired from "components/TrainingSessionsSelect/NoTrainingSessionRequired";

const TrainingSession = props => {
  if (props.trainingRequirement) {
    return (
      <TrainingSessionsSelect
        booking={{
          ...props.booking.data.attributes,
          links: props.booking.links,
        }}
        trainingRequirement={props.trainingRequirement}
        liveTrainings={props.liveTrainings}
        liveOnlineTrainings={props.liveOnlineTrainings}
        onDemandVideoTrainings={props.onDemandVideoTrainings}
      />
    );
  } else {
    return <NoTrainingSessionRequired links={props.booking.links} />;
  }
};

export default TrainingSession;
