import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

import AvailableTimeSlot from 'components/shared/AvailableTimeSlot';

const AvailableTimeSlots = (props) => {
  const { slotDate, availableTimes, selectedSlots, updateSelectedSlotFn, seatsAvailabilityCheckRequired } = props;
  const selectedSlotsIds = selectedSlots.map((slot) => slot.id);
  const availableTimeSlots = availableTimes.map((availableTime) => {
    return (
      <AvailableTimeSlot
        key={availableTime.id}
        availableTime={availableTime}
        slotSelected={selectedSlotsIds.includes(availableTime.id)}
        updateSelectedSlotFn={updateSelectedSlotFn}
        seatsAvailabilityCheckRequired={seatsAvailabilityCheckRequired}
      />
    );
  });

  return (
    <div className="available-time-slots">
      <header className="available-time-slots__header">
        <div className="available-time-slots__date">{format(slotDate, 'MMMM D, YYYY')}</div>
        <div className="available-time-slots__day">{format(slotDate, 'dddd').toUpperCase()}</div>
      </header>
      {availableTimeSlots}
    </div>
  );
};

AvailableTimeSlots.propTypes = {
  slotDate: PropTypes.string.isRequired,
  availableTimes: PropTypes.array.isRequired,
  selectedSlots: PropTypes.array.isRequired,
  updateSelectedSlotFn: PropTypes.func.isRequired,
};

export default AvailableTimeSlots;
