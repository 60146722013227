import React from "react";
import PropTypes from "prop-types";

import AddToCalendar from "react-add-to-calendar";
import { SHIFT_TRAINING_TYPES_WITH_VISIBLE_LOCATION } from "./constants";

const eventNameCopies = {
  shift: "Volunteer for Election Protection",
  training: "Training for Election Protection",
};

export const getEventDetails = (selectedDate, { address, type: eventType }) => {
  const eventStartTime = selectedDate.iso8601StartAt;
  const eventEndTime = selectedDate.iso8601EndAt;
  const eventLocation = SHIFT_TRAINING_TYPES_WITH_VISIBLE_LOCATION.includes(
    eventType,
  )
    ? address.location
    : "";

  return {
    eventStartTime,
    eventEndTime,
    eventLocation,
  };
};

const AddEventToCalendar = ({ item, selectedDate, volunteeringDetailType }) => {
  const { eventStartTime, eventEndTime, eventLocation } = getEventDetails(
    selectedDate,
    item,
  );
  const event = {
    title: `${eventNameCopies[volunteeringDetailType]}`,
    description: "",
    location: eventLocation,
    startTime: eventStartTime,
    endTime: eventEndTime,
  };

  return <AddToCalendar event={event} buttonLabel="Calendar" />;
};

export default AddEventToCalendar;

AddEventToCalendar.propTypes = {
  volunteeringDetailType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedDate: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
};
