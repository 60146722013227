import React from "react";
import ReactDOM from "react-dom";

import ResendVerificationEmail from "components/ResendVerificationEmail";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.querySelector("#root_resend_verification_email");
  if (node) {
    const resendVerificationEmailUrl = node.dataset.resendVerificationEmailUrl;

    ReactDOM.render(
      <ResendVerificationEmail
        resendVerificationEmailUrl={resendVerificationEmailUrl}
      />,
      node,
    );
  }
});
