import * as yup from 'yup';

const nameValidation = /^\D.+$/;
const phoneValidation = /^[\d-\(\) ]+$/;
export const zipCodeValidation = /^[0-9]{5}(?:-[0-9]{4})?$/;

const validationSchema = yup.object().shape({
  volunteer: yup.object().shape({
    firstName: yup
      .string()
      .matches(nameValidation, 'First name is invalid')
      .nullable()
      .required('First name is required'),
    lastName: yup
      .string()
      .matches(nameValidation, 'Last name is invalid')
      .nullable()
      .required('Last name is required'),
    email: yup
      .string()
      .nullable()
      .required('Email is required')
      .email('Must be a valid email address'),
    password: yup
      .string()
      .nullable()
      .when('$context', (context, schema) => {
        if (context && context === 'create') {
          return schema.min(8, 'Password must be 8 characters or more').required('Password is required');
        } else {
          return schema.min(8, 'Password must be 8 characters or more');
        }
      }),
    volunteerProfile: yup.object().shape({
      zipCode: yup
        .string()
        .nullable()
        .matches(zipCodeValidation, 'Home zip code is invalid')
        .required('Home zip code is required'),
      mobilePhone: yup
        .string()
        .matches(phoneValidation, 'Cell Phone number is invalid')
        .nullable()
        .required('Cell phone number is required'),
      workPhone: yup
        .string()
        .matches(phoneValidation, { message: 'Work Phone number is invalid', excludeEmptyString: true })
        .nullable(),
      languages: yup
        .array()
        .nullable()
        .required('You must select at least one language'),
      referredBy: yup
        .array()
        .nullable()
        .required('You must select at least one reference'),
      hasCar: yup.boolean().nullable(),
      hasVolunteered: yup
        .boolean()
        .nullable()
        .required('You must select an option'),
      willingToTravel: yup
        .string()
        .nullable()
        .required('This field is required'),
      legalType: yup
        .string()
        .nullable()
        .required('Legal type is required'),
      licensedStates: yup
        .array()
        .nullable()
        .when('legalType', {
          is: (legalType) => !!legalType && legalType !== 'No legal experience' && legalType !== 'Other',
          then: yup.array().required('You must select at least one option'),
        }),
      yearsOfExperience: yup
        .string()
        .nullable()
        .when('legalType', {
          is: (legalType) => !!legalType && legalType !== 'No legal experience' && legalType !== 'Other',
          then: yup.string().required('Years of experience is required'),
        }),
      areasOfPractice: yup
        .array()
        .nullable()
        .when('legalType', {
          is: (legalType) => !!legalType && legalType !== 'No legal experience' && legalType !== 'Other',
          then: yup.array().required('You must select at least one option'),
        }),
      areasOfInterest: yup
        .array()
        .nullable()
        .when('legalType', {
          is: (legalType) => !!legalType && legalType !== 'No legal experience' && legalType !== 'Other',
          then: yup.array().required('You must select at least one area of interest'),
        }),
      legalSkills: yup
        .array()
        .nullable()
        .when('legalType', {
          is: (legalType) => !!legalType && legalType !== 'No legal experience' && legalType !== 'Other',
          then: yup.array().required('You must select at least one option'),
        }),
    }),
  }),
});

export default validationSchema;
