import React from "react";
import ReactDOM from "react-dom";

import TrainingSession from "components/TrainingSessionsSelect/TrainingSession";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.querySelector("#root_training_session");
  if (node) {
    const booking = JSON.parse(node.dataset.booking);
    const trainingRequirement = JSON.parse(node.dataset.trainingRequirement)
      .data;
    const liveTrainings = JSON.parse(node.dataset.liveTrainings).data;
    const liveOnlineTrainings = JSON.parse(node.dataset.liveOnlineTrainings)
      .data;
    const onDemandVideoTrainings = JSON.parse(
      node.dataset.onDemandVideoTrainings,
    ).data;

    ReactDOM.render(
      <TrainingSession
        booking={booking}
        trainingRequirement={trainingRequirement}
        liveTrainings={liveTrainings}
        liveOnlineTrainings={liveOnlineTrainings}
        onDemandVideoTrainings={onDemandVideoTrainings}
      />,
      node,
    );
  }
});
