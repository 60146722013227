import React from "react";
import Field from "./Field";

import { Field as FormikField } from "formik";

const RadioField = props => {
  const {
    name,
    label,
    tooltip,
    values,
    touched,
    errors,
    options,
    isRequired,
  } = props;

  return (
    <Field
      label={label}
      name={name}
      tooltip={tooltip}
      values={values}
      touched={touched}
      errors={errors}
      isRequired={isRequired}
      render={({ name, value }) => {
        return (
          <div className="input-radio-group">
            {Object.entries(options).map(([label, optionValue]) => {
              const key = `${name}.${label}`;
              return (
                <div className="input-radio-wrapper" key={key}>
                  <FormikField
                    type="radio"
                    name={name}
                    value={optionValue}
                    id={key}
                    checked={value.toString() === optionValue.toString()}
                    className="input-radio"
                  />
                  <label className="input-radio-label" htmlFor={key}>
                    {label}
                  </label>
                </div>
              );
            })}
          </div>
        );
      }}
      {...props}
    />
  );
};

export default RadioField;
