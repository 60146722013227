import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TrainingTypes from './TrainingTypes';
import TrainingLocations from './TrainingLocations';
import ConfirmSessionButton from './ConfirmSessionButton';
import * as R from 'ramda';

import { csrfFetch } from 'lib/utils';

import { LIVE_TRAININGS, LIVE_ONLINE_TRAININGS, ON_DEMAND_VIDEO_TRAININGS } from './constants';

const trainingTypesMap = {
  LiveTraining: LIVE_TRAININGS,
  LiveOnlineWebinar: LIVE_ONLINE_TRAININGS,
  OnDemandVideo: ON_DEMAND_VIDEO_TRAININGS,
};

class TrainingSessionsSelect extends Component {
  // liveTrainings
  // liveOnlineTrainings
  // onDemandVideoTrainings
  constructor(props) {
    super(props);

    this.state = {
      selectedTrainingType: this.defaultSelectedTrainingTypeFn(),
      selectedTrainingSession: this.defaultTrainingSessionFn(),
    };

    this.selectTrainingTypeFn = this.selectTrainingTypeFn.bind(this);
    this.updateSelectedSessionFn = this.updateSelectedSessionFn.bind(this);
    this.confirmTrainingSessionsFn = this.confirmTrainingSessionsFn.bind(this);
  }

  defaultSelectedTrainingTypeFn() {
    const { trainingRequirement } = this.props;
    if (trainingRequirement.attributes.trainingSession) {
      return trainingTypesMap[trainingRequirement.attributes.trainingSession.trainingType];
    } else if (!this.areTrainingSessionsAvailable() && this.getOnDemandVideoTrainingSession()) {
      return ON_DEMAND_VIDEO_TRAININGS;
    } else {
      return null;
    }
  }

  defaultTrainingSessionFn() {
    const { trainingRequirement } = this.props;

    if (trainingRequirement.attributes.trainingSession) {
      return trainingRequirement.attributes.trainingSession;
    } else if (!this.areTrainingSessionsAvailable() && this.getOnDemandVideoTrainingSession()) {
      return this.getOnDemandVideoTrainingSession();
    } else {
      return null;
    }
  }

  getOnDemandVideoTrainingSession() {
    const getAvailableTimes = R.path(['onDemandVideoTrainings', 0, 'attributes', 'sessions', 0, 'availableTimes', 0]);
    return getAvailableTimes(this.props);
  }

  areSessionsInTrainingFn(training) {
    return training.some((training) => training.attributes.sessions.length);
  }

  areTrainingSessionsAvailable() {
    const { liveTrainings, liveOnlineTrainings } = this.props;
    return this.areSessionsInTrainingFn(liveTrainings) || this.areSessionsInTrainingFn(liveOnlineTrainings);
  }

  selectTrainingTypeFn(type) {
    const newSelectedTrainingSession =
      type === ON_DEMAND_VIDEO_TRAININGS ? this.getOnDemandVideoTrainingSession() : null;

    this.setState({ selectedTrainingType: type, selectedTrainingSession: newSelectedTrainingSession });
  }

  updateSelectedSessionFn(trainingSession) {
    const { selectedTrainingSession } = this.state;
    if (selectedTrainingSession && selectedTrainingSession.id === trainingSession.id) {
      this.setState({ selectedTrainingSession: null });
    } else {
      this.setState({ selectedTrainingSession: trainingSession });
    }
  }

  confirmTrainingSessionsFn() {
    const { booking, trainingRequirement } = this.props;
    const { selectedTrainingSession } = this.state;

    // Rails will accept the following schema:
    //
    // booking[training_requirements_attributes][0][training_session_id]
    // booking[training_requirements_attributes][0][id]

    const body = JSON.stringify({
      booking: {
        training_requirements_attributes: {
          0: {
            id: trainingRequirement.id,
            training_session_id: selectedTrainingSession.id,
          },
        },
      },
    });

    csrfFetch(booking.links.self, {
      method: 'PATCH',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      response.json().then((data) => {
        if (data.status == 'ok') {
          document.location.href = data.booking.links.next;
        }
      });
    });
  }

  render() {
    const { selectedTrainingType, selectedTrainingSession } = this.state;
    const { trainingRequirement } = this.props;
    const locations = selectedTrainingType ? this.props[selectedTrainingType] : null;

    return (
      <div>
        <TrainingTypes
          {...this.props}
          trainingRequirement={trainingRequirement.attributes}
          selectedTrainingType={selectedTrainingType}
          selectTrainingTypeFn={this.selectTrainingTypeFn}
        />

        <TrainingLocations
          locations={locations}
          daysThreshold={trainingRequirement.attributes.daysThreshold}
          shiftDateUnderOnDemandSessionThreshold={trainingRequirement.attributes.shiftDateUnderOnDemandSessionThreshold}
          selectedTrainingSession={selectedTrainingSession}
          selectedTrainingType={selectedTrainingType}
          updateSelectedSessionFn={this.updateSelectedSessionFn}
        />

        <ConfirmSessionButton
          selectedTrainingSession={selectedTrainingSession}
          selectedTrainingType={selectedTrainingType}
          confirmTrainingSessionsFn={this.confirmTrainingSessionsFn}
        />
      </div>
    );
  }
}

TrainingSessionsSelect.propTypes = {
  booking: PropTypes.object.isRequired,
  trainingRequirement: PropTypes.object.isRequired,
  liveTrainings: PropTypes.arrayOf(PropTypes.object).isRequired,
  liveOnlineTrainings: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDemandVideoTrainings: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TrainingSessionsSelect;
