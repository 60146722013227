import React from "react";
import PropTypes from "prop-types";

import Field from "lib/forms/Field";

const PasswordForm = props => {
  const {
    formConfig: { passwordFormHeading, showPasswordFormDescription },
  } = props;

  return (
    <section className="complete-profile-form-section password-form">
      <h2 className="complete-profile-form__section-heading">
        {passwordFormHeading}
      </h2>
      {showPasswordFormDescription && (
        <p className="complete-profile-form__section-description">
          This will be your password to login to your account to manage your
          profile and volunteer shifts.
        </p>
      )}

      <Field
        label="Password"
        name="volunteer.password"
        type="password"
        isRequired
        {...props}
      />
    </section>
  );
};

PasswordForm.propTypes = {
  errors: PropTypes.object.isRequired,
  formConfig: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default PasswordForm;
