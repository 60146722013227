import React from 'react';
import PropTypes from 'prop-types';

import { googleMapsURL } from 'lib/utils';

import AvailableDates from './AvailableDates';

import LocationDistance from 'components/shared/LocationDistance';

import { LIVE_TRAININGS, LIVE_ONLINE_TRAININGS } from './constants';

const TrainingLocationHeading = ({ location, selectedTrainingType }) => {
  if (selectedTrainingType === LIVE_ONLINE_TRAININGS) {
    return (
      <div className="select-training-sessions-location__info">
        <h4 className="select-training-sessions-location__training-title">{location.title}</h4>
        <small className="select-training-sessions-location__training-description">{location.description}</small>
      </div>
    );
  }

  const fullAddress1 = location.address.fullAddress1;
  const fullAddress2 = location.address.fullAddress2;

  return (
    <div className="select-training-sessions-location__info">
      <small className="select-training-sessions-location__heading">{location.locationName}</small>
      <small className="select-training-sessions-location__address">{fullAddress1}</small>
      <small className="select-training-sessions-location__details">{fullAddress2}</small>
      <h4 className="select-training-sessions-location__training-title">{location.title}</h4>
      <small className="select-training-sessions-location__training-description">{location.description}</small>
    </div>
  );
};

const TrainingLocationMap = ({ location, selectedTrainingType }) => {
  if (selectedTrainingType !== LIVE_TRAININGS) {
    return null;
  }

  return (
    <div className="select-training-sessions-location__map">
      <a
        className="select-training-sessions-location__map-link"
        href={googleMapsURL(location.address.location)}
        target="_blank"
      >
        View Map
      </a>
      <LocationDistance distance={location.address.distance} />
    </div>
  );
};

const TrainingLocation = ({ location, selectedTrainingSession, updateSelectedSessionFn, selectedTrainingType }) => {
  return (
    <div className="select-training-sessions-location">
      <header className="select-training-sessions-location__header">
        <TrainingLocationHeading location={location} selectedTrainingType={selectedTrainingType} />
        <TrainingLocationMap location={location} selectedTrainingType={selectedTrainingType} />
      </header>

      <AvailableDates
        sessions={location.sessions}
        selectedTrainingSession={selectedTrainingSession}
        updateSelectedSessionFn={updateSelectedSessionFn}
        seatsAvailabilityCheckRequired={selectedTrainingType === LIVE_TRAININGS}
      />
    </div>
  );
};

TrainingLocation.propTypes = {
  location: PropTypes.object.isRequired,
  selectedTrainingSession: PropTypes.object,
  updateSelectedSessionFn: PropTypes.func.isRequired,
  selectedTrainingType: PropTypes.string,
};

TrainingLocationHeading.propTypes = {
  location: PropTypes.object.isRequired,
  selectedOpportunityType: PropTypes.string,
};

TrainingLocationMap.propTypes = {
  selectedTrainingType: PropTypes.string,
};

export default TrainingLocation;
