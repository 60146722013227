import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { googleMapsURL } from "lib/utils";
import AvailableDates from "./AvailableDates";
import LocationDistance from "components/shared/LocationDistance";

const hasCaptainShifts = location => {
  return location.captainShifts && location.captainShifts.length > 0;
};

const FieldProgramVolunteerOpportunityLocationHeading = ({ location }) => {
  const { name, address } = location;
  const { fullAddress1, fullAddress2 } = address;

  return (
    <div className="select-shifts-location__info">
      <h4 className="select-shifts-location__heading">{name}</h4>
      <small className="select-shifts-location__address">{fullAddress1}</small>
      <small className="select-shifts-location__details">{fullAddress2}</small>
    </div>
  );
};

const DefaultVolunteerOpportunityLocationHeading = ({ location }) => {
  const { fullAddress1, fullAddress2 } = location.address;
  const isRemote = location.remote;

  const addressInfo = (
    <>
      <small className="select-shifts-location__address">{fullAddress1}</small>
      <small className="select-shifts-location__details">{fullAddress2}</small>
    </>
  );

  const remoteInfo = (
    <small className="select-shifts-location__details">
      Remote Opportunity
    </small>
  );

  return (
    <div className="select-shifts-location__info">
      <h4 className="select-shifts-location__heading">{location.name}</h4>
      {isRemote ? remoteInfo : addressInfo}
    </div>
  );
};

const VolunteerOpportunityLocationHeading = ({
  selectedOpportunityType,
  location,
}) => {
  const LocationHeadingComponent =
    selectedOpportunityType === "fieldPrograms"
      ? FieldProgramVolunteerOpportunityLocationHeading
      : DefaultVolunteerOpportunityLocationHeading;

  return <LocationHeadingComponent location={location} />;
};

const VolunteerOpportunityLocationMap = ({
  location,
  selectedOpportunityType,
}) => {
  return (
    <div className="select-shifts-location__map">
      {selectedOpportunityType === "callCenters" && (
        <a
          className="select-shifts-location__map-link"
          href={googleMapsURL(location.address.location)}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Map
        </a>
      )}
      <LocationDistance distance={location.address.distance} />
    </div>
  );
};

const VolunteerOpportunityLocationAdditionalInfo = ({ location }) => (
  <div>
    <h4 className="select-shifts-location__heading--details">
      Additional Details
    </h4>
    <span className="select-shifts-location__details">
      {location.additionalDetails}
    </span>
  </div>
);

const VolunteerOpportunityLocation = ({
  selectedOpportunityType,
  location,
  selectedShifts,
  updateSelectedShiftsFn,
}) => {
  const locationClassnames = classNames("select-shifts-location", {
    "select-shifts-location--field_program":
      selectedOpportunityType === "fieldPrograms",
  });
  const shouldDisplayCaptainShiftHeader = hasCaptainShifts(location);
  const regularShifts =
    selectedOpportunityType === "fieldPrograms"
      ? location.shifts
      : location.volunteerShifts;
  const { remote } = location;

  return (
    <div className={locationClassnames}>
      <header className="select-shifts-location__header">
        <div className="select-shifts-location__header--box">
          <VolunteerOpportunityLocationHeading
            selectedOpportunityType={selectedOpportunityType}
            location={location}
          />
          {!remote && (
            <VolunteerOpportunityLocationMap
              selectedOpportunityType={selectedOpportunityType}
              location={location}
            />
          )}
        </div>
        {location.additionalDetails && (
          <VolunteerOpportunityLocationAdditionalInfo location={location} />
        )}
      </header>
      <AvailableDates
        shifts={location.captainShifts}
        selectedShifts={selectedShifts}
        updateSelectedShiftsFn={updateSelectedShiftsFn}
        heading={() =>
          shouldDisplayCaptainShiftHeader && (
            <header className="select-shifts-available-carousel__header">
              <h5 className="select-shifts-available-carousel__heading">
                Captain Shifts
              </h5>
            </header>
          )
        }
      />
      <AvailableDates
        shifts={regularShifts}
        heading={() =>
          hasCaptainShifts(location) && (
            <header className="select-shifts-available-carousel__header">
              <h5 className="select-shifts-available-carousel__heading">
                Volunteer Shifts
              </h5>
            </header>
          )
        }
        selectedShifts={selectedShifts}
        updateSelectedShiftsFn={updateSelectedShiftsFn}
      />
    </div>
  );
};

VolunteerOpportunityLocation.propTypes = {
  location: PropTypes.shape({
    address: PropTypes.shape({}),
    shifts: PropTypes.arrayOf(PropTypes.shape({})),
    volunteerShifts: PropTypes.arrayOf(PropTypes.shape({})),
    remote: PropTypes.bool,
    additionalDetails: PropTypes.string,
    captainShifts: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  selectedShifts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateSelectedShiftsFn: PropTypes.func.isRequired,
  selectedOpportunityType: PropTypes.string.isRequired,
};

VolunteerOpportunityLocationHeading.propTypes = {
  location: PropTypes.shape({
    address: PropTypes.string,
  }).isRequired,
  selectedOpportunityType: PropTypes.string.isRequired,
};

VolunteerOpportunityLocationMap.propTypes = {
  location: PropTypes.shape({
    address: PropTypes.shape({
      location: PropTypes.string,
      distance: PropTypes.number,
    }),
    shifts: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  selectedOpportunityType: PropTypes.string.isRequired,
};

export default VolunteerOpportunityLocation;
