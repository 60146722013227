import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

class SelectInput extends Component {
  handleChange = (value) => {
    const { id, onChange } = this.props;
    onChange(id, value.value);
  };

  handleBlur = () => {
    const { id, onBlur } = this.props;
    onBlur(id, true);
  };

  render() {
    const { id, options, value, disabled } = this.props;
    return (
      <ReactSelect
        searchable={false}
        clearable={false}
        id={id}
        options={options}
        multi={false}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        value={value}
        disabled={disabled}
      />
    );
  }
}

SelectInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
};

export default SelectInput;
