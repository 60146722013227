import React from 'react';
import PropTypes from 'prop-types';

import { VOLUNTEER_TYPES } from './constants';
import VolunteeringDetailItems from './VolunteeringDetailItems';

import Modal, { ModalFooter } from 'lib/modal';

const CancelShiftModal = ({ closeModalFn, modalIsOpen, shift, onCancelShift }) => {
  return (
    <Modal
      className="cancel-shift-modal"
      isOpen={modalIsOpen}
      closeModalFn={closeModalFn}
      heading="Cancel Volunteer Shift"
      subheading="Are you sure you want to cancel your volunteer shift?"
    >
      <VolunteeringDetailItems
        items={[shift]}
        volunteeringDetailType="shifts"
        addEventToCalendarActionRequired={false}
        viewMapActionRequired={false}
      />
      <ModalFooter>
        <p className="cancel-shift-modal__footer-note">
          <strong>NOTE:</strong> Your {VOLUNTEER_TYPES[shift.attributes.type]} Training will also be cancelled.
        </p>
        <button
          tabIndex="0"
          className="button secondary-button secondary-button--large cancel-shift-button"
          onClick={onCancelShift}
          type="button"
        >
          Yes, Cancel Shift
        </button>
        <button onClick={closeModalFn} className="keep-shift-button" type="button">
          No, Keep Shift
        </button>
      </ModalFooter>
    </Modal>
  );
};

CancelShiftModal.propTypes = {
  closeModalFn: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  onCancelShift: PropTypes.func.isRequired,
  scrollPosition: PropTypes.number,
  shift: PropTypes.object,
};

export default CancelShiftModal;
