// The following function was based (almost verbatim) from the snippet available
// in the Segment's quickstart documentation.
// See. https://segment.com/docs/sources/website/analytics.js/quickstart/
//
// Changes from the original version:
//  - Return the global analytics if is already initialised, to allow
//    referencing the client elsewhere.
//  - Replace `initialize` with `initialized`, seems to be a typo in the
//    documentation.
function initializeSegment(writeKey) {
  if (!writeKey) throw new Error('Segment write key is required.');

  // Create a queue, but don't obliterate an existing one!
  var analytics = (window.analytics = window.analytics || []);

  // If the snippet was invoked already on the page return.
  if (analytics.initialized || analytics.invoked) return analytics;

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function(method) {
    return function() {
      var args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };

  // For each of our methods, generate a queueing stub.
  for (var i = 0; i < analytics.methods.length; i++) {
    var key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function(key) {
    // Create an async script element based on your key.
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src =
      ('https:' === document.location.protocol ? 'https://' : 'http://') +
      'cdn.segment.com/analytics.js/v1/' +
      key +
      '/analytics.min.js';

    // Insert our script next to the first script element.
    var first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
  };

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.0.0';

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  analytics.load(writeKey);
  return window.analytics;
}

const segmentClient = (key) => {
  return {
    get client() {
      return window.analytics ? window.analytics : initializeSegment(key);
    },
  };
};

export default segmentClient;
