import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import VolunteerOpportunityLocation from './VolunteerOpportunityLocation';

const selectLocationsWithShifts = R.filter(
  R.compose(
    R.length,
    R.path(['attributes', 'shifts']),
  ),
);

const VolunteerOpportunityLocations = ({
  locations,
  selectedShifts,
  updateSelectedShiftsFn,
  selectedOpportunityType,
}) => {
  if (!locations) {
    return null;
  }

  const opportunityLocations = selectLocationsWithShifts(locations).map((location) => (
    <VolunteerOpportunityLocation
      key={location.id}
      location={location.attributes}
      selectedShifts={selectedShifts}
      updateSelectedShiftsFn={updateSelectedShiftsFn}
      selectedOpportunityType={selectedOpportunityType}
    />
  ));

  return <div className="shifts-select__locations">{opportunityLocations}</div>;
};

VolunteerOpportunityLocations.propTypes = {
  locations: PropTypes.array,
  selectedShifts: PropTypes.array.isRequired,
  updateSelectedShiftsFn: PropTypes.func.isRequired,
  selectedOpportunityType: PropTypes.string,
};

export default VolunteerOpportunityLocations;
