import React from 'react';
import PropTypes from 'prop-types';
import TrainingType from './TrainingType';

import { LIVE_TRAININGS, LIVE_ONLINE_TRAININGS, ON_DEMAND_VIDEO_TRAININGS } from './constants';

const anySessionInTrainings = (trainings) => {
  return trainings.some((training) => training.attributes.sessions.length);
};

const TrainingTypes = ({
  liveTrainings,
  liveOnlineTrainings,
  selectedTrainingType,
  selectTrainingTypeFn,
  onDemandVideoTrainings,
}) => {
  const anyLiveTraining = anySessionInTrainings(liveTrainings);
  const anyLiveOnlineTraining = anySessionInTrainings(liveOnlineTrainings);
  const anyLiveOnDemandTraining = anySessionInTrainings(onDemandVideoTrainings);

  return (
    <div className="select-training-sessions-types">
      <TrainingType
        trainingType={LIVE_TRAININGS}
        hasSessions={anyLiveTraining}
        selectedTrainingType={selectedTrainingType}
        selectTrainingTypeFn={selectTrainingTypeFn}
        enabled={anyLiveTraining}
        selected={selectedTrainingType === LIVE_TRAININGS}
      />
      <TrainingType
        trainingType={LIVE_ONLINE_TRAININGS}
        hasSessions={anyLiveOnlineTraining}
        selectedTrainingType={selectedTrainingType}
        selectTrainingTypeFn={selectTrainingTypeFn}
        enabled={anyLiveOnlineTraining}
        selected={selectedTrainingType === LIVE_ONLINE_TRAININGS}
      />
      <TrainingType
        trainingType={ON_DEMAND_VIDEO_TRAININGS}
        selectedTrainingType={selectedTrainingType}
        selectTrainingTypeFn={selectTrainingTypeFn}
        enabled={anyLiveOnDemandTraining}
        selected={selectedTrainingType === ON_DEMAND_VIDEO_TRAININGS}
      />
    </div>
  );
};

TrainingTypes.propTypes = {
  liveTrainings: PropTypes.arrayOf(PropTypes.object),
  liveOnlineTrainings: PropTypes.arrayOf(PropTypes.object),
  selectedTrainingType: PropTypes.string,
  selectTrainingTypeFn: PropTypes.func.isRequired,
};

export default TrainingTypes;
