import React from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";
import { timeRangeToString } from "lib/utils";
import { getTimeZoneAbbr } from "lib/constants";

// TODO: Move this format to a config, so it can be reused acrross the project.
const availableDateFormat = "MMMM D, dddd";

function isShiftSelected(selectedShifts, shiftId) {
  return selectedShifts.find(
    ({ id: selectedShiftId }) => selectedShiftId === shiftId,
  );
}

const ConfirmShiftsSlots = ({
  availableTimes,
  availableDate,
  selectedShifts,
  updateSelectedShiftsFn,
}) => {
  return (
    <div className="confirm-shifts-slots">
      {availableTimes.map(availableTime => {
        return (
          isShiftSelected(selectedShifts, availableTime.id) && (
            <ConfirmShiftsSlot
              availableDate={availableDate}
              availableTime={availableTime}
              key={availableTime.id}
              selectedShifts={selectedShifts}
              updateSelectedShiftsFn={updateSelectedShiftsFn}
            />
          )
        );
      })}
    </div>
  );
};

const ConfirmShiftsSlot = ({
  availableTime,
  availableDate,
  updateSelectedShiftsFn,
}) => {
  return (
    <div className="confirm-shifts-slot">
      <p className="confirm-shifts-slot__datetime">
        {format(availableDate, availableDateFormat)}
      </p>
      <p className="confirm-shifts-slot__datetime">
        {timeRangeToString(
          availableTime.iso8601StartAt,
          availableTime.iso8601EndAt,
          getTimeZoneAbbr,
        )}
      </p>
      <span
        className="confirm-shifts-slot__remove"
        onClick={() => {
          updateSelectedShiftsFn(availableTime);
        }}
        tabIndex="0"
      >
        Cancel
      </span>
    </div>
  );
};

export default ConfirmShiftsSlots;

ConfirmShiftsSlot.propTypes = {
  availableDate: PropTypes.string.isRequired,
  availableTime: PropTypes.shape({
    iso8601StartAt: PropTypes.string,
    iso8601EndAt: PropTypes.string,
  }).isRequired,
  selectedShifts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateSelectedShiftsFn: PropTypes.func.isRequired,
};

ConfirmShiftsSlots.propTypes = {
  availableDate: PropTypes.string.isRequired,
  availableTimes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedShifts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateSelectedShiftsFn: PropTypes.func.isRequired,
};
