import React from "react";
import PropTypes from "prop-types";

export const TooltipHelper = ({ children }) => {
  return <span className="tooltip-helper">{children}</span>;
};

const Tooltip = ({ children }) => {
  return (
    <div className="tooltip tooltip-trigger">
      <div className="tooltip-content">{children}</div>
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

TooltipHelper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tooltip;
