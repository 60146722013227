const TIMEZONE_MAPPING = {
  "America/Chicago": "CT",
  "America/Bahia_Banderas": "CT",
  "America/El_Salvador": "CT",
  "America/Indiana/Knox": "CT",
  "America/Managua": "CT",
  "America/Menominee": "CT",
  "America/Mexico_City": "CT",
  "America/North_Dakota/Beulah": "CT",
  "America/North_Dakota/New_Salem": "CT",
  "America/Rankin_Inlet": "CT",
  "America/Resolute": "CT",
  "America/Tegucigalpa": "CT",
  "America/Belize": "CT",
  "America/Costa_Rica": "CT",
  "America/Guatemala": "CT",
  "America/Indiana/Tell_City": "CT",
  "America/Matamoros": "CT",
  "America/Merida": "CT",
  "America/Monterrey": "CT",
  "America/North_Dakota/Center": "CT",
  "America/Rainy_River": "CT",
  "America/Regina": "CT",
  "America/Swift_Current": "CT",
  "America/Winnipeg": "CT",
  "America/New_York": "ET",
  "America/Atikokan": "ET",
  "America/Cancun": "ET",
  "America/Cayman": "ET",
  "America/Detroit": "ET",
  "America/Grand_Turk": "ET",
  "America/Indiana/Indianapolis": "ET",
  "America/Indiana/Marengo": "ET",
  "America/Indiana/Petersburg": "ET",
  "America/Indiana/Vevay": "ET",
  "America/Indiana/Vincennes": "ET",
  "America/Indiana/Winamac": "ET",
  "America/Iqaluit": "ET",
  "America/Jamaica": "ET",
  "America/Kentucky/Louisville": "ET",
  "America/Kentucky/Monticello": "ET",
  "America/Nassau": "ET",
  "America/Nipigon": "ET",
  "America/Panama": "ET",
  "America/Pangnirtung": "ET",
  "America/Port-au-Prince": "ET",
  "America/Santo_Domingo": "ET",
  "America/Thunder_Bay": "ET",
  "America/Toronto": "ET",
  "Pacific/Honolulu": "HST",
  "America/Adak": "HST",
  "America/Los_Angeles": "PT",
  "America/Tijuana": "PT",
  "America/Vancouver": "PT",
  "Asia/Manila": "PT",
  "America/Denver": "MT",
  "America/Boise": "MT",
  "America/Cambridge_Bay": "MT",
  "America/Chihuahua": "MT",
  "America/Creston": "MT",
  "America/Dawson": "MT",
  "America/Dawson_Creek": "MT",
  "America/Edmonton": "MT",
  "America/Fort_Nelson": "MT",
  "America/Hermosillo": "MT",
  "America/Inuvik": "MT",
  "America/Mazatlan": "MT",
  "America/Ojinaga": "MT",
  "America/Phoenix": "MT",
  "America/Whitehorse": "MT",
  "America/Yellowknife": "MT",
  "America/Juneau": "AKDT",
};

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimeZoneAbbr = TIMEZONE_MAPPING[timezone];
