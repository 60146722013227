import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

const filterEmpty = R.reject(R.isEmpty);

export const ContactInfo = ({ info }) => {
  const infoList = filterEmpty([
    info.contactName,
    info.contactPhone,
    info.contactEmail,
  ]);
  const [title, data1, data2] = infoList;

  return (
    <>
      {title && <p className="contact-info__title">Contact: {title}</p>}
      {(data1 || data2) && (
        <p className="contact-info__additional-info">
          {data1 && <span className="contact-info__poc">{data1}</span>}
          {data2 && <span className="contact-info__poc">{data2}</span>}
        </p>
      )}
    </>
  );
};

ContactInfo.propTypes = {
  info: PropTypes.shape({
    contactName: PropTypes.string,
    contactPhone: PropTypes.string,
    contactEmail: PropTypes.string,
  }).isRequired,
};

export default ContactInfo;
