import React from 'react';
import PropTypes from 'prop-types';

import CarouselDates from 'components/shared/CarouselDates';
import AvailableTimeSlots from 'components/shared/AvailableTimeSlots';

const AvailableDates = ({ shifts, selectedShifts, updateSelectedShiftsFn, heading }) => {
  if (!shifts || !shifts.length) {
    return null;
  }

  const shiftItemMapperFn = (shift) => {
    return (
      <AvailableTimeSlots
        availableTimes={shift.availableTimes}
        key={shift.date}
        selectedSlots={selectedShifts}
        slotDate={shift.date}
        updateSelectedSlotFn={updateSelectedShiftsFn}
        seatsAvailabilityCheckRequired={true}
      />
    );
  };

  return (
    <div className="select-shifts-available-dates">
      {heading && heading()}
      <CarouselDates items={shifts} itemMapperFn={shiftItemMapperFn} />
    </div>
  );
};

AvailableDates.propTypes = {
  shifts: PropTypes.array,
  selectedShifts: PropTypes.array.isRequired,
  updateSelectedShiftsFn: PropTypes.func.isRequired,
  heading: PropTypes.func,
};

export default AvailableDates;
