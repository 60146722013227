import React, { Component } from "react";
import { Field as FormikField, getIn } from "formik";
import classNames from "classnames";
import { render } from "react-dom";

const errorMessage = (key, touched, errors) => {
  const error = getIn(errors, key);
  const isTouched = getIn(touched, key);

  return isTouched && error ? error : null;
};

const OptionalLabelHelper = () => {
  return <small className="form-optional-label-helper">(optional)</small>;
};

const RequiredLabelHelper = () => {
  return <small className="form-required-label-helper">(required)</small>;
};

const FieldLabel = ({ label, tooltip, isOptional, isRequired, ...rest }) => {
  return (
    <label className="form-label" {...rest}>
      {label}
      {tooltip && tooltip()}
      {isOptional && <OptionalLabelHelper />}
      {isRequired && <RequiredLabelHelper />}
    </label>
  );
};

const FieldError = ({ message }) => {
  if (!message) {
    return null;
  }

  return <p className="form-error">{message}</p>;
};

const valueIsDefined = value => {
  return value !== null && typeof value !== "undefined";
};

class Field extends Component {
  constructor(props) {
    super(props);
    this.id =
      props.inputId ||
      Math.random()
        .toString(36)
        .substr(2);
  }

  render() {
    const {
      type,
      name,
      label,
      tooltip,
      isOptional,
      isRequired,
      values,
      touched,
      errors,
      className,
      placeholder,
    } = this.props;
    const DEFAULT_FIELD_TYPE = "text";
    const DEFAULT_FIELD_VALUE = "";
    let innerField = null;
    const customClassnames = classNames(className, "form-element");
    const value = getIn(values, name);

    if (this.props.render) {
      innerField = this.props.render({
        name: name,
        id: this.id,
        value: valueIsDefined(value) ? value : DEFAULT_FIELD_VALUE,
      });
    } else {
      innerField = (
        <FormikField
          type={type || DEFAULT_FIELD_TYPE}
          className="input-text complete-profile-form__field"
          name={name}
          id={this.id}
          value={valueIsDefined(value) ? value : DEFAULT_FIELD_VALUE}
          placeholder={placeholder}
        />
      );
    }

    return (
      <div className={customClassnames}>
        <FieldLabel
          label={label}
          tooltip={tooltip}
          isOptional={isOptional}
          isRequired={isRequired}
          htmlFor={this.id}
        />

        {innerField}

        <FieldError message={errorMessage(name, touched, errors)} />
      </div>
    );
  }
}

export default Field;
