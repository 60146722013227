import React, { useState } from "react";
import PropTypes from "prop-types";
import VolunteeringDetailItems from "./VolunteeringDetailItems";
import CancelShiftModal from "./CancelShiftModal";
import { csrfFetch } from "lib/utils";
import * as R from "ramda";

const volunteeringTypeConfig = {
  heading: {
    shift: "Volunteer Shifts",
    training: "Training",
  },
  subheading: totalItems => ({
    shift: `${totalItems} shift(s) selected`,
    training: `${totalItems} selected`,
  }),
};

const VolunteeringMainHeading = ({ hasShiftsAndTrainings }) => {
  const shiftsNote = hasShiftsAndTrainings
    ? "You have no upcoming shifts or trainings."
    : `If you need to cancel a shift, please click the “x” to the right of the
  shift you need to cancel.`;

  return (
    <div class="volunteer-shifts">
      <h2 class="volunteer-shifts__heading">Your current shifts</h2>
      <p class="volunteer-shifts__note">{shiftsNote}</p>
    </div>
  );
};

const VolunteeringDetailType = ({
  items,
  itemsTotalCount,
  volunteeringDetailType,
  openCancelShiftModalFn,
}) => {
  if (items.length === 0) {
    return null;
  }
  const heading = volunteeringTypeConfig.heading;
  const subheading = volunteeringTypeConfig.subheading(itemsTotalCount);

  return (
    <div className="volunteering-detail-type">
      <header className="volunteering-detail-type__header">
        <h3 className="volunteering-detail-type__heading">
          {heading[volunteeringDetailType]}
        </h3>
        <small className="volunteering-detail-type__subheading">
          {subheading[volunteeringDetailType]}
        </small>
      </header>

      <VolunteeringDetailItems
        items={items}
        volunteeringDetailType={volunteeringDetailType}
        openCancelShiftModalFn={openCancelShiftModalFn}
      />
    </div>
  );
};

const VolunteeringDetailPrintButton = () => {
  return (
    <button
      type="button"
      className="volunteering-detail__print-button"
      onClick={() => window.print()}
    >
      Print
    </button>
  );
};

function VolunteeringDetail({ shiftsData, trainingSessionsData, links }) {
  const [shiftToCancel, setShiftToCancel] = useState(null);
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
  const hasShiftsAndTrainings =
    R.isEmpty(shiftsData.shifts) &&
    R.isEmpty(trainingSessionsData.trainingSessions);

  const openCancelShiftModalFn = shiftToCancel => {
    setCancelModalIsOpen(true);
    setShiftToCancel(shiftToCancel);
  };

  const closeCancelShiftModalFn = () => {
    setCancelModalIsOpen(false);
    setShiftToCancel(null);
  };

  const cancelShift = () => {
    const { id: shiftToCancelId } = shiftToCancel.attributes.shifts[0];

    csrfFetch(`${links.cancelShift}${shiftToCancelId}`, {
      method: "DELETE",
      body: null,
      headers: {
        "Content-Type": "application/json",
      },
    }).then(response => {
      response.json().then(data => {
        if (data.status == "ok") {
          document.location.reload();
        }
      });
    });
  };

  return (
    <div className="volunteering-detail">
      <VolunteeringMainHeading hasShiftsAndTrainings={hasShiftsAndTrainings} />

      {!hasShiftsAndTrainings ? <VolunteeringDetailPrintButton /> : null}

      <VolunteeringDetailType
        items={shiftsData.shifts}
        itemsTotalCount={shiftsData.selectedShiftsCount}
        volunteeringDetailType="shift"
        openCancelShiftModalFn={openCancelShiftModalFn}
      />

      <VolunteeringDetailType
        items={trainingSessionsData.trainingSessions}
        itemsTotalCount={trainingSessionsData.selectedTrainingSessionsCount}
        volunteeringDetailType="training"
      />

      {!hasShiftsAndTrainings ? (
        <p className="volunteering-detail__note">
          <strong>NOTE:</strong> In order to modify your training selection, you
          must cancel your shift(s) and re-sign up for them.
        </p>
      ) : null}

      {cancelModalIsOpen && (
        <CancelShiftModal
          modalIsOpen={cancelModalIsOpen}
          closeModalFn={closeCancelShiftModalFn}
          shift={shiftToCancel}
          onCancelShift={cancelShift}
        />
      )}
    </div>
  );
}

VolunteeringDetail.propTypes = {
  links: PropTypes.object.isRequired,
  shiftsData: PropTypes.object.isRequired,
  trainingSessionsData: PropTypes.object.isRequired,
};

VolunteeringDetailType.propTypes = {
  heading: PropTypes.string.isRequired,
  openCancelShiftModalFn: PropTypes.func,
  volunteeringDetailType: PropTypes.string.isRequired,
};

export default VolunteeringDetail;
