import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LIVE_TRAININGS, LIVE_ONLINE_TRAININGS, ON_DEMAND_VIDEO_TRAININGS } from './constants';

const selectTrainingsConfig = {
  [LIVE_TRAININGS]: {
    title: 'Live Training',
    description: 'Receive training in person',
    recommended: true,
    className: '',
  },
  [LIVE_ONLINE_TRAININGS]: {
    title: 'Live Online Webinar',
    description: 'Receive training via live online webinar',
    recommended: true,
    className: '',
  },
  [ON_DEMAND_VIDEO_TRAININGS]: {
    title: 'On-Demand Video',
    description: 'Watch training video(s) at your convenience',
    recommended: false,
    className: 'select-training-sessions-type--on-demand-video',
  },
};

const RecommendedTag = ({ recommended, hasSessions }) => {
  if (!recommended || !hasSessions) {
    return null;
  }

  return <small className="recommended-tag">Recommended</small>;
};

class TrainingType extends Component {
  handleClick = () => {
    const { enabled, trainingType, selectTrainingTypeFn } = this.props;

    if (enabled) {
      selectTrainingTypeFn(trainingType);
    }
  };

  render() {
    const { enabled, selected, hasSessions, trainingType } = this.props;
    const { title, description, recommended, className } = selectTrainingsConfig[trainingType];

    const trainingTypeClassnames = classNames(className, 'select-training-sessions-type', {
      'select-training-sessions-type--selected': selected,
      'select-training-sessions-type--disabled': !enabled,
    });

    return (
      <div className={trainingTypeClassnames} onClick={this.handleClick}>
        <div className="select-training-sessions-type__info">
          <h3 className="select-training-sessions-type__heading">{title}</h3>
          <p className="select-training-sessions-type__description">{description}</p>
          <RecommendedTag recommended={recommended} hasSessions={hasSessions} />
        </div>
      </div>
    );
  }
}

TrainingType.propTypes = {
  trainingType: PropTypes.string.isRequired,
  selectedTrainingType: PropTypes.string,
  selectTrainingTypeFn: PropTypes.func.isRequired,
  hasSessions: PropTypes.bool,
  enabled: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
};

RecommendedTag.propTypes = {
  recommended: PropTypes.bool.isRequired,
  hasSessions: PropTypes.bool,
};

export default TrainingType;
