import React from 'react';
import PropTypes from 'prop-types';

import TrainingLocation from './TrainingLocation';

import { ON_DEMAND_VIDEO_TRAININGS } from './constants';

const OnDemandTrainingSession = ({ daysThreshold, shiftDateUnderThreshold }) => {
  const note = shiftDateUnderThreshold
    ? 'once your volunteer shifts are confirmed'
    : daysThreshold + ' days before your volunteer shift';
  return (
    <div className="on-demand-session">
      <h5 className="on-demand-session__heading">On-demand Video training will be sent to your email</h5>
      <p className="on-demand-session__note">Your on-demand training videos will be sent to you via email {note}.</p>
    </div>
  );
};

const TrainingLocations = ({
  locations,
  daysThreshold,
  shiftDateUnderOnDemandSessionThreshold,
  selectedTrainingSession,
  updateSelectedSessionFn,
  selectedTrainingType,
}) => {
  if (selectedTrainingType === ON_DEMAND_VIDEO_TRAININGS) {
    return (
      <OnDemandTrainingSession
        daysThreshold={daysThreshold}
        shiftDateUnderThreshold={shiftDateUnderOnDemandSessionThreshold}
      />
    );
  }

  if (!locations) {
    return null;
  }

  const sessionLocations = locations.map((location) => {
    return (
      <TrainingLocation
        key={location.id}
        location={location.attributes}
        selectedTrainingSession={selectedTrainingSession}
        selectedTrainingType={selectedTrainingType}
        updateSelectedSessionFn={updateSelectedSessionFn}
      />
    );
  });

  return <div className="select-training-session__locations">{sessionLocations}</div>;
};

TrainingLocations.propTypes = {
  locations: PropTypes.array,
  shiftDateUnderOnDemandSessionThreshold: PropTypes.bool,
  selectedTrainingSession: PropTypes.object,
  updateSelectedSessionFn: PropTypes.func.isRequired,
  selectedTrainingType: PropTypes.string,
};

export default TrainingLocations;
