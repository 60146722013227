import React, { Component } from "react";
import PropTypes from "prop-types";

import VolunteerOpportunityTypes from "./VolunteerOpportunityTypes";
import VolunteerOpportunityLocations from "./VolunteerOpportunityLocations";
import ConfirmShiftsButton from "./ConfirmShiftsButton";
import ConfirmShiftsModal from "./ConfirmShiftsModal";

import { csrfFetch } from "lib/utils";

class VolunteerShiftsSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOpportunityType: this.defaultSelectedOpportunityType(),
      confirmShiftsModalIsOpen: false,
      selectedShifts: this.props.booking.selectedShifts,
    };

    this.selectOpportunityTypeFn = this.selectOpportunityTypeFn.bind(this);
    this.updateSelectedShiftsFn = this.updateSelectedShiftsFn.bind(this);
    this.confirmShiftsFn = this.confirmShiftsFn.bind(this);
    this.openConfirmShiftsModalFn = this.openConfirmShiftsModalFn.bind(this);
    this.closeConfirmShiftsModalFn = this.closeConfirmShiftsModalFn.bind(this);
  }

  // TODO: Improve this
  defaultSelectedOpportunityType() {
    const { callCenters, booking } = this.props;
    const firstSelectedShift =
      booking.selectedShifts.length && booking.selectedShifts[0];

    if (firstSelectedShift) {
      if (
        callCenters.find(
          cc => cc.id == firstSelectedShift.volunteerOpportunityId,
        )
      ) {
        return "callCenters";
      } else {
        return "fieldPrograms";
      }
    } else {
      return null;
    }
  }

  selectOpportunityTypeFn(type) {
    this.setState({ selectedOpportunityType: type });
  }

  getUpdatedSelectedShifts(selectedShift) {
    const { selectedShifts } = this.state;
    if (selectedShifts.find(shift => shift.id === selectedShift.id)) {
      return selectedShifts.filter(shift => shift.id != selectedShift.id);
    }
    return selectedShifts.concat(selectedShift);
  }

  updateSelectedShiftsFn(selectedShift) {
    this.setState(
      {
        selectedShifts: this.getUpdatedSelectedShifts(selectedShift),
      },
      () => {
        if (
          this.state.selectedShifts.length === 0 &&
          this.state.confirmShiftsModalIsOpen
        ) {
          this.closeConfirmShiftsModalFn();
        }
      },
    );
  }

  openConfirmShiftsModalFn() {
    this.setState({
      confirmShiftsModalIsOpen: true,
    });
  }

  closeConfirmShiftsModalFn() {
    this.setState({ confirmShiftsModalIsOpen: false });
  }

  confirmShiftsFn() {
    const { booking } = this.props;
    const shiftIds = this.state.selectedShifts.map(shift => shift.id);
    csrfFetch(booking.links.self, {
      method: "PATCH",
      body: JSON.stringify({ booking: { shift_ids: shiftIds } }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(response => {
      response.json().then(data => {
        if (data.status == "ok") {
          document.location.href = data.booking.links.next;
        } else {
          const errorMessage = data.errors.shift_ids?.includes("are closed")
            ? "One or more of the selected shifts are not longer available. Please refresh the page."
            : "There was a problem confirming your selected shift(s). Please refresh the page and try again.";

          this.closeConfirmShiftsModalFn();
          alert(errorMessage);
        }
      });
    });
  }

  render() {
    const {
      selectedOpportunityType,
      selectedShifts,
      confirmShiftsModalIsOpen,
    } = this.state;
    const { callCenters, fieldPrograms, booking } = this.props;

    const locations = selectedOpportunityType
      ? this.props[selectedOpportunityType]
      : null;
    const availableShifts = callCenters.concat(fieldPrograms);

    return (
      <div>
        <VolunteerOpportunityTypes
          {...this.props}
          selectedOpportunityType={selectedOpportunityType}
          selectOpportunityTypeFn={this.selectOpportunityTypeFn}
        />

        <VolunteerOpportunityLocations
          booking={booking}
          locations={locations}
          selectedShifts={selectedShifts}
          selectedOpportunityType={selectedOpportunityType}
          updateSelectedShiftsFn={this.updateSelectedShiftsFn}
        />

        <ConfirmShiftsButton
          numberOfShifts={selectedShifts.length}
          selectedOpportunityType={selectedOpportunityType}
          openConfirmShiftsModalFn={this.openConfirmShiftsModalFn}
        />

        {confirmShiftsModalIsOpen && (
          <ConfirmShiftsModal
            availableShifts={availableShifts}
            selectedShifts={selectedShifts}
            modalIsOpen={confirmShiftsModalIsOpen}
            closeModalFn={this.closeConfirmShiftsModalFn}
            confirmShiftsFn={this.confirmShiftsFn}
            updateSelectedShiftsFn={this.updateSelectedShiftsFn}
          />
        )}
      </div>
    );
  }
}

VolunteerShiftsSelect.propTypes = {
  callCenters: PropTypes.array.isRequired,
  fieldPrograms: PropTypes.array.isRequired,
  booking: PropTypes.object,
};

export default VolunteerShiftsSelect;
