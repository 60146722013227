import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { csrfFetch } from "lib/utils";

class ResendVerificationEmail extends Component {
  constructor(props) {
    super(props);
    this.state = { verificationSent: false };
  }

  handleClick() {
    csrfFetch(this.props.resendVerificationEmailUrl, {
      method: "POST",
    }).then(() => {
      this.setState({ verificationSent: true });
      setTimeout(() => {
        this.setState({ verificationSent: false });
      }, 20000);
    });
  }

  verificationSentMessage() {
    if (this.state.verificationSent) {
      return (
        <p className="verify-your-email__resent-verification-email">
          Verification re-sent to your email.
        </p>
      );
    }
  }

  render() {
    const buttonClassnames = classNames(
      "button",
      "secondary-button",
      "secondary-button--large",
      "verify-your-email__resend-verification-email",
      {
        "secondary-button--disabled": this.state.verificationSent,
      },
    );

    return (
      <Fragment>
        <button
          className={buttonClassnames}
          disabled={this.state.verificationSent}
          onClick={this.handleClick.bind(this)}
          type="button"
        >
          Resend verification email
        </button>
        {this.verificationSentMessage()}
      </Fragment>
    );
  }
}

export default ResendVerificationEmail;

ResendVerificationEmail.propTypes = {
  resendVerificationEmailUrl: PropTypes.string.isRequired,
};
