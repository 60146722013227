import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CallCenterLogo from "images/call-center-logo.svg";
import FieldProgramLogo from "images/field-program-logo.svg";

const selectShiftsTypes = {
  callCenters: {
    title: "Call Center",
    description:
      "Answer voters’ questions and respond to issues through the 866-OUR-VOTE hotline",
    logoSrc: CallCenterLogo,
  },
  fieldPrograms: {
    title: "Field Program",
    description:
      "Respond to and monitor voting problems in targeted locations as they arise.",
    logoSrc: FieldProgramLogo,
  },
};

class VolunteerOpportunityType extends Component {
  handleClick = () => {
    const {
      opportunities,
      opportunityType,
      selectOpportunityTypeFn,
    } = this.props;

    if (opportunities.length > 0) {
      selectOpportunityTypeFn(opportunityType);
    }
  };

  render() {
    const {
      opportunities,
      opportunityType,
      selectedOpportunityType,
    } = this.props;

    const numberOfAvailableShifts = opportunities.reduce(
      (accumulator, opportunity) => {
        return accumulator + opportunity.attributes.numberOfAvailableShifts;
      },
      0,
    );

    const opportunityTypeClassnames = classNames("select-shifts-type", {
      "select-shifts-type--selected":
        selectedOpportunityType === opportunityType,
      "select-shifts-type--disabled": opportunities.length === 0,
    });
    const { title, description, logoSrc } = selectShiftsTypes[opportunityType];

    return (
      <div className={opportunityTypeClassnames} onClick={this.handleClick}>
        <div className="select-shifts-type__logo-wrapper">
          <img className="select-shifts-type__logo" src={logoSrc} alt="" />
        </div>
        <div className="select-shifts-type__info">
          <h3 className="select-shifts-type__heading">
            {title} ({numberOfAvailableShifts})
          </h3>
          <p className="select-shifts-type__description">{description}</p>
        </div>
      </div>
    );
  }
}

VolunteerOpportunityType.propTypes = {
  opportunityType: PropTypes.string.isRequired,
  opportunities: PropTypes.array.isRequired,
  selectedOpportunityType: PropTypes.string,
  selectOpportunityTypeFn: PropTypes.func.isRequired,
};

export default VolunteerOpportunityType;
