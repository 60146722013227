import React from "react";
import ReactDOM from "react-dom";
import MissingVolunteerModal from "components/MissingVolunteerModal";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.querySelector("#modal-root");
  if (node) {
    const processMissingVolunteerUrl = node.dataset.processMissingVolunteerUrl;
    const signInUrl = node.dataset.signInUrl;
    const legalTypeOptions = JSON.parse(node.dataset.legalTypeOptions);

    ReactDOM.render(
      <MissingVolunteerModal
        processMissingVolunteerUrl={processMissingVolunteerUrl}
        legalTypeOptions={legalTypeOptions}
        signInUrl={signInUrl}
      />,
      node,
    );
  }
});
