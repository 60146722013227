import React, { Component } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isMobile } from 'lib/utils';
import {
  BODY_CONTENT_WRAPPER_SELECTOR,
  BODY_CONTENT_SELECTOR,
  BODY_HEADER_SELECTOR,
  MODAL_OFFSET_TOP_FOR_DESKTOP,
} from './constants';

export const ModalFooter = ({ children }) => {
  return <footer className="modal-footer">{children}</footer>;
};

class Modal extends Component {
  state = {
    lastScrollPosition: null,
  };

  afterCloseModal = () => {
    this.resetBodyContentTopPosition();
    this.resetBodyContentHeight();
    this.updateScrollPosition(this.state.lastScrollPosition);
  };

  resetBodyContentTopPosition = () => {
    document.querySelector(BODY_CONTENT_SELECTOR).style.top = 0;
  };

  resetBodyContentHeight = () => {
    document.querySelector(BODY_CONTENT_WRAPPER_SELECTOR).style.height = 'auto';
  };

  onAfterOpen = () => {
    this.updateTopPositionAndHeight();
    this.updateScrollPosition(0);
  };

  calculateVisibleContentArea = (offsetTop) => {
    const browserViewportHeight = window.innerHeight;
    const modalContentHeight = this.modalContentRef.offsetHeight;
    const visbleContentHeight = modalContentHeight + offsetTop;
    return visbleContentHeight > browserViewportHeight ? visbleContentHeight : browserViewportHeight;
  };

  updateTopPositionAndHeight = () => {
    const modalContentRef = this.modalContentRef;

    if (!modalContentRef) {
      return;
    }

    const isMobileViewport = isMobile();
    const modalOffsetTop = this.calculateModalOffsetTop();
    const visibleContentArea = this.calculateVisibleContentArea(modalOffsetTop);

    this.updateBodyContentHeight(visibleContentArea);

    if (!isMobileViewport) {
      this.updateModalOverlayHeight(visibleContentArea);
      this.updateBodyContentTopPosition(-this.state.lastScrollPosition);
    }
  };

  calculateModalOffsetTop = () => {
    return isMobile() ? document.querySelector(BODY_HEADER_SELECTOR).offsetHeight : MODAL_OFFSET_TOP_FOR_DESKTOP;
  };

  updateBodyContentTopPosition = (topPosition) => {
    document.querySelector(BODY_CONTENT_SELECTOR).style.top = `${topPosition}px`;
  };

  updateBodyContentHeight = (height) => {
    document.querySelector(BODY_CONTENT_WRAPPER_SELECTOR).style.height = `${height}px`;
  };

  updateModalOverlayHeight = (height) => {
    const overlayRef = this.overlayRef;

    if (overlayRef) {
      overlayRef.style.height = `${height}px`;
    }
  };

  updateScrollPosition = (scrollPosition) => {
    window.scrollTo(0, scrollPosition);
  };

  static getDerivedStateFromProps(props) {
    if (props.isOpen) {
      return {
        lastScrollPosition: window.pageYOffset,
      };
    }

    return null;
  }

  componentDidUpdate() {
    if (isMobile()) {
      this.updateTopPositionAndHeight();
    }
  }

  componentWillUnmount() {
    this.afterCloseModal();
  }

  render() {
    const { isOpen, children, className, closeModalFn, heading, subheading } = this.props;
    const modalOverlayClassname = classNames('modal-overlay', { 'modal-overlay--open': isOpen });
    const modalClassname = classNames('modal', className);

    return (
      <ReactModal
        ariaHideApp={false}
        className={modalClassname}
        isOpen={isOpen}
        onRequestClose={closeModalFn}
        onAfterOpen={this.onAfterOpen}
        overlayClassName={modalOverlayClassname}
        overlayRef={(node) => (this.overlayRef = node)}
        contentRef={(node) => (this.modalContentRef = node)}
        shouldFocusAfterRender={false}
      >
        <header className="modal-header">
          <h3 className="modal-heading">{heading}</h3>
          <small className="modal-subheading">{subheading}</small>
          <span className="modal-close" tabIndex="0" onClick={closeModalFn} />
        </header>
        {children}
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  className: PropTypes.string,
  closeModalFn: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  scrollPosition: PropTypes.number,
  children: PropTypes.node,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
};

export default Modal;
