import React from "react";
import PropTypes from "prop-types";
import Modal from "lib/modal";
import MissingVolunteerForm from "./Form";

const TERMS_OF_USE_URL = "https://wetheaction.org/terms_of_use";
const PRIVACY_POLICY_URL = "https://wetheaction.org/privacy_policy";

const MissingVolunteerModal = ({
  processMissingVolunteerUrl,
  signInUrl,
  legalTypeOptions,
}) => {
  return (
    <Modal
      isOpen
      className="missing-volunteer-modal"
      heading="Sign up for a shift:"
      subheading=""
      closeModalFn={() => null}
    >
      <div className="modal-body">
        <div className="modal-title">Sign up for a shift:</div>
        <MissingVolunteerForm
          actionUrl={processMissingVolunteerUrl}
          legalTypeOptions={legalTypeOptions}
        />

        <p className="login-call">
          Already a volunteer? &nbsp;
          <a className="login-call-link" href={signInUrl}>
            Log In
          </a>
        </p>

        <p className="signup-form__disclaimer">
          By clicking “Get Started”, you agree to our&nbsp;
          <a
            target="_blank"
            className="signup-form__disclaimer-link"
            href={TERMS_OF_USE_URL}
          >
            Terms of Use
          </a>
          . Please see our&nbsp;
          <a target="_blank" className="footer-link" href={PRIVACY_POLICY_URL}>
            Privacy Policy
          </a>
          .
        </p>
      </div>
    </Modal>
  );
};

MissingVolunteerModal.propTypes = {
  processMissingVolunteerUrl: PropTypes.string.isRequired,
  signInUrl: PropTypes.string.isRequired,
  legalTypeOptions: PropTypes.arrayOf(PropTypes.object),
};

export default MissingVolunteerModal;
