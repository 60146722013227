import React from "react";
import ReactDOM from "react-dom";

import VolunteeringDetail from "components/VolunteeringDetail";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.querySelector("#root_volunteering_detail");
  if (node) {
    const volunteeringDetailData = JSON.parse(
      node.dataset.volunteeringDetailData,
    ).data.attributes;
    const links = JSON.parse(node.dataset.links);

    ReactDOM.render(
      <VolunteeringDetail
        shiftsData={volunteeringDetailData.shifts}
        trainingSessionsData={volunteeringDetailData.trainingSessions}
        links={links}
      />,
      node,
    );
  }
});
