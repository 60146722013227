import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import classNames from "classnames";

import { isMobile } from "lib/utils";

export const initReactSelect = () => {
  document.querySelectorAll("[data-react-select-input]").forEach(el => {
    const options = JSON.parse(el.dataset.reactSelectOptions);
    const values = JSON.parse(el.dataset.reactSelectValues);
    const name = el.dataset.reactSelectName;
    const placeholder = el.dataset.reactSelectPlaceholder;
    const hasErrors = !!el.dataset.reactSelectErrors;

    ReactDOM.render(
      <SelectWithHiddenField
        name={name}
        options={options}
        placeholder={placeholder}
        defaultValues={values}
        multi={!!el.dataset.reactSelectMulti}
        hasErrors={hasErrors}
      />,
      el,
    );
  });
};

const SelectHiddenInput = ({ values, name, multi, id }) => {
  // If no values are present, we need to render an empty input so Rails can process
  // it as a blank value.
  if (!values || values.length === 0) {
    return <input type="hidden" name={name} value="" />;
  }

  if (multi) {
    return values.map((value, idx) => (
      <input
        key={`input-${idx}`}
        type="hidden"
        name={name}
        value={value.value}
      />
    ));
  }

  return <input type="hidden" name={name} value={values.value} />;
};

class SelectWithHiddenField extends Component {
  state = {
    values: this.props.values ? this.props.values : this.props.defaultValues,
  };

  getValues = () =>
    this.props.values !== undefined ? this.props.values : this.state.values;

  setValues = values => {
    this.setState({ values });
    if (this.props.onChange) {
      this.props.onChange(values);
    }
  };

  clearValues = () => this.setValues([]);

  render() {
    const {
      name,
      options,
      multi,
      className,
      hasErrors,
      value,
      onFocus,
      onBlur,
      isDisabled,
      placeholder,
      id,
      clearable,
    } = this.props;
    const values = this.getValues();

    const classnames = classNames(className, {
      "react-select-with-errors": hasErrors,
    });
    const closeMenuOnSelect = !multi && !isMobile();

    return (
      <Fragment>
        <SelectHiddenInput values={values} name={name} multi={multi} />
        <ReactSelect
          classNamePrefix="react-select"
          className={classnames}
          placeholder={placeholder || "Select"}
          options={options}
          onChange={this.setValues}
          defaultValue={value}
          value={values}
          joinValues
          isMulti={multi}
          clearable={clearable}
          id={id}
          inputId={id}
          isSearchable={multi}
          onFocus={onFocus}
          onBlur={onBlur}
          closeMenuOnSelect={closeMenuOnSelect}
          isDisabled={isDisabled}
        />
      </Fragment>
    );
  }
}

SelectWithHiddenField.propTypes = {
  /** Available options **/
  options: PropTypes.array.isRequired,

  /** Selected value(s). Pass this if you want to control the component **/
  values: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),

  /** Initial selected value(s). Pass this if you want this component to be uncontrolled **/
  defaultValues: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),

  /** Name that the input(s) that hold the values will have **/
  name: PropTypes.string.isRequired,

  /** If the input has any error. If so the input will have a redish border **/
  hasErrors: PropTypes.bool,

  /** If the input is multi or not **/
  multi: PropTypes.bool,
};

SelectHiddenInput.propTypes = {
  values: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  name: PropTypes.string,
  isMulti: PropTypes.bool,
};

export default SelectWithHiddenField;
