import snakeCase from "lodash.snakecase";
import { setIn } from "formik";
import format from "date-fns/format";

const googleMapsURL = address => {
  return encodeURI(
    `https://www.google.com/maps/search/?api=1&query=${address}&zoom=10`,
  );
};

const csrfToken = () => {
  // FIXME: If the meta tag with the name 'csrf-token' doesn't exist, the selector will be
  // null and will raise an error when trying to call `content`. It seems like the intention
  // here was to throw an error if the meta tag was not found, so this needs to be fixed.
  return (
    document.querySelector("meta[name=csrf-token]").content ||
    new Error("No CSRF Token found.")
  );
};

const csrfFetch = (url, options) => {
  const defaultHeaders = {
    Accept: "application/json",
    "X-CSRF-Token": csrfToken(),
    "Content-Type": "application/json",
  };
  const finalHeaders = Object.assign({}, defaultHeaders, options.headers);

  const defaultOptions = {
    credentials: "same-origin",
  };
  const finalOptions = Object.assign({}, defaultOptions, options, {
    headers: finalHeaders,
  });

  return fetch(url, finalOptions).catch(function(error) {
    throw new Error(
      "There has been a problem with your request: " + error.message,
    );
  });
};

const isMobile = () => {
  const desktopBreakpoint = 1200;
  return window.innerWidth <= desktopBreakpoint;
};

const isDate = object => {
  return Object.prototype.toString.call(object) === "[object Date]";
};

const isRegex = object => {
  return Object.prototype.toString.call(object) === "[object RegExp]";
};

const isObject = object => {
  return typeof object === "object";
};

const snakeize = object => {
  if (!object || !isObject(object)) return object;
  if (isDate(object) || isRegex(object)) return object;
  if (Array.isArray(object)) return object.map(snakeize);

  return Object.keys(object).reduce((acc, key) => {
    acc[snakeCase(key)] = snakeize(object[key]);
    return acc;
  }, {});
};

const normalizeRailsErrorKey = key => {
  return key
    .split(".")
    .pop()
    .replace(/\b\w/g, l => l.toUpperCase());
};

const railsErrorsToFormikErrors = errors => {
  let errorsHash = {};
  for (let [key, value] of Object.entries(errors)) {
    errorsHash = setIn(
      errorsHash,
      key,
      `${normalizeRailsErrorKey(key)} ${value}`,
    );
  }
  return errorsHash;
};

const timeFormat = "h:mm A";

const timeRangeToString = (startTime, endTime, timezone) => {
  if (!startTime || !endTime) {
    return "Time TBD";
  }

  const startTimeF = format(startTime, timeFormat);
  const endTimeF = format(endTime, timeFormat);
  let timeRange = `${startTimeF} - ${endTimeF}`;

  if (timezone) timeRange += ` ${timezone}`;

  return timeRange;
};

export {
  googleMapsURL,
  csrfFetch,
  isMobile,
  snakeize,
  railsErrorsToFormikErrors,
  timeRangeToString,
};
