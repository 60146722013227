import React from "react";
import PropTypes from "prop-types";
import { Field as FormikField } from "formik";

import MultiSelectField from "lib/forms/MultiSelectField";
import SelectField from "lib/forms/SelectField";
import Tooltip, { TooltipHelper } from "components/shared/Tooltip";
import * as R from "ramda";

const LegalLawyerForm = props => {
  const {
    states,
    areasOfPractice,
    areasOfInterest,
    legalSkills,
    yearsOfExperience,
  } = props;
  return (
    <div className="legal-lawyer-form">
      <MultiSelectField
        label="State(s) Licensed to Practice Law"
        name="volunteer.volunteerProfile.licensedStates"
        options={states}
        tooltip={() => (
          <Tooltip>
            Select the states you are currently barred in.
            <TooltipHelper>
              Note: You do not need legal experience to volunteer for Election
              Protection
            </TooltipHelper>
          </Tooltip>
        )}
        isRequired
        {...props}
      />

      <SelectField
        label="Years of Experience"
        name="volunteer.volunteerProfile.yearsOfExperience"
        options={yearsOfExperience}
        isRequired
        {...props}
      />

      <MultiSelectField
        label="Areas of Practice"
        name="volunteer.volunteerProfile.areasOfPractice"
        options={areasOfPractice}
        isRequired
        {...props}
      />

      <MultiSelectField
        label="Areas of Interest"
        name="volunteer.volunteerProfile.areasOfInterest"
        options={areasOfInterest}
        isRequired
        {...props}
      />

      <MultiSelectField
        label="Legal Skills"
        name="volunteer.volunteerProfile.legalSkills"
        options={legalSkills}
        isRequired
        {...props}
      />
    </div>
  );
};

const LegalTypeSelector = props => {
  const { legalType, onlyLawyerRelatedLegalTypes } = props;
  let component = null;
  if (legalType) {
    component = (
      <FormikField type="hidden" name="volunteer.volunteerProfile.legalType" />
    );
  } else {
    component = (
      <SelectField
        label="Legal Experience"
        name="volunteer.volunteerProfile.legalType"
        options={onlyLawyerRelatedLegalTypes}
        {...props}
      />
    );
  }
  return component;
};

const LegalExperienceForm = props => {
  const {
    areasOfInterest,
    areasOfPractice,
    errors,
    legalSkills,
    legalType,
    legalTypes: defaultLegalTypes,
    setFieldTouchedFn,
    setFieldValueFn,
    states,
    touched,
    values,
    yearsOfExperience,
  } = props;

  const onlyLawyerRelatedLegalTypes = R.reject(
    R.propEq("value", "No legal experience"),
    defaultLegalTypes,
  );

  return (
    <section className="complete-profile-form-section">
      <h2 className="complete-profile-form__section-heading">
        Legal Experience
      </h2>
      <p className="complete-profile-form__section-description">
        The information below is helpful for volunteer organizers in
        understanding your experience. This information is also helpful in
        suggesting future pro-bono volunteer opportunities.
      </p>

      <LegalTypeSelector
        onlyLawyerRelatedLegalTypes={onlyLawyerRelatedLegalTypes}
        legalType={legalType}
        {...props}
      />

      <LegalLawyerForm
        areasOfInterest={areasOfInterest}
        areasOfPractice={areasOfPractice}
        errors={errors}
        legalSkills={legalSkills}
        legalTypes={defaultLegalTypes}
        setFieldTouchedFn={setFieldTouchedFn}
        setFieldValueFn={setFieldValueFn}
        states={states}
        touched={touched}
        values={values}
        yearsOfExperience={yearsOfExperience}
      />
    </section>
  );
};

LegalExperienceForm.propTypes = {
  areasOfInterest: PropTypes.array.isRequired,
  areasOfPractice: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  legalSkills: PropTypes.array.isRequired,
  legalTypes: PropTypes.array.isRequired,
  setFieldTouchedFn: PropTypes.func.isRequired,
  setFieldValueFn: PropTypes.func.isRequired,
  states: PropTypes.array.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  yearsOfExperience: PropTypes.array.isRequired,
  legalType: PropTypes.string,
};

export default LegalExperienceForm;
