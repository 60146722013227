import React from "react";
import PropTypes from "prop-types";

import { timeRangeToString } from "lib/utils";
import format from "date-fns/format";

import VolunteeringDetailAddEvent from "./VolunteeringDetailAddEvent";
import ContactInfo from "./ContactInfo";
import { getTimeZoneAbbr } from "lib/constants";

// TODO: Move this format to a config, so it can be reused acrross the project.
const availableDateFormat = "MMMM D, dddd";

function VolunteeringDetailDate({
  openCancelShiftModalFn,
  item: shift,
  selectedDate,
  volunteeringDetailType,
  addEventToCalendarActionRequired,
}) {
  const openCancelShiftModal = () => {
    const shiftToCancelAttributes = {
      ...shift.attributes,
      ...{ shifts: [selectedDate] },
    };
    const shiftToCancel = {
      ...shift,
      ...{ attributes: shiftToCancelAttributes },
    };

    openCancelShiftModalFn(shiftToCancel);
  };
  const shouldDisplayRemoveAction = volunteeringDetailType === "shift";
  return (
    <div className="volunteering-detail-date">
      <p className="volunteering-detail-date__datetime">
        {format(selectedDate.date, availableDateFormat)}
      </p>
      <p className="volunteering-detail-date__datetime">
        {timeRangeToString(
          selectedDate.iso8601StartAt,
          selectedDate.iso8601EndAt,
          getTimeZoneAbbr,
        )}
      </p>
      <ContactInfo info={selectedDate} />

      <div className="volunteering-detail-date__wrapper">
        {shouldDisplayRemoveAction && (
          <span
            className="volunteering-detail-date__remove"
            onClick={openCancelShiftModal}
            tabIndex="0"
          >
            Cancel
          </span>
        )}
        {addEventToCalendarActionRequired && (
          <VolunteeringDetailAddEvent
            item={shift.attributes}
            selectedDate={selectedDate}
            volunteeringDetailType={volunteeringDetailType}
          />
        )}
      </div>
    </div>
  );
}

export default VolunteeringDetailDate;

VolunteeringDetailDate.propTypes = {
  volunteeringDetailType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedDate: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  addEventToCalendarActionRequired: PropTypes.bool.isRequired,
  openCancelShiftModalFn: PropTypes.func,
};
