import React from 'react';
import PropTypes from 'prop-types';
import ConfirmShiftsLocation from './ConfirmShiftsLocation';

const ConfirmShiftsLocations = ({ locations, selectedShifts, updateSelectedShiftsFn }) => {
  return (
    <div className="confirm-shifts-locations">
      {locations.map((location) => (
        <ConfirmShiftsLocation
          key={location.id}
          location={location.attributes}
          selectedShifts={selectedShifts}
          updateSelectedShiftsFn={updateSelectedShiftsFn}
        />
      ))}
    </div>
  );
};

ConfirmShiftsLocations.propTypes = {
  locations: PropTypes.array.isRequired,
  selectedShifts: PropTypes.array.isRequired,
  updateSelectedShiftsFn: PropTypes.func.isRequired,
};

export default ConfirmShiftsLocations;
