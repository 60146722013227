import SegmentClient from './SegmentClient';

// This wrapper aims to abstract the logic behind client initialization
// and user identification. This is because it is a repetitive task.
class EventTracker {
  constructor() {
    this.segmentWriteKey = document.querySelector('meta[name="segment-write-key"]').content;
    this.analytics = SegmentClient(this.segmentWriteKey);

    const userMetaTag = document.querySelector('meta[name="user-id"]');
    if (userMetaTag) {
      this.analytics.client.identify(userMetaTag.content);
    }
  }

  // For more reference on the options argument, please visit:
  // https://segment.com/docs/sources/website/analytics.js/#track-link
  //
  // Please use an adequate name as the event title.
  // Use this format as a reference:
  //   "[Verb that indicates the action (infinitive)] [preposition] [description of event's subject]"
  // Eg.
  //   "Click on the Login link"
  //   "Click on the Profile link"
  //
  // Usage example:
  //
  //   import EventTracker from 'lib/eventTracker';
  //   tracker.trackEvent(document.querySelector('.link'), 'Click on the blue link');
  trackLink(element, title, options) {
    this.analytics.client.trackLink(element, title, options);
  }

  // For more reference on the options argument, please visit:
  // https://segment.com/docs/sources/website/analytics.js/#track-form
  //
  // Please use an adequate name as the event title.
  //
  // Usage example:
  //
  //   import EventTracker from 'lib/eventTracker';
  //   tracker.trackForm(document.querySelector('.signup-form'), 'Signed Up');
  trackForm(element, title, options) {
    this.analytics.client.trackForm(element, title, options);
  }

  // For more reference on the options argument, please visit:
  // https://segment.com/docs/sources/website/analytics.js/#page
  //
  // This can be used to track view page
  //
  // Usage example:
  //
  //   import EventTracker from 'lib/eventTracker';
  //
  //   tracker.trackPageView('Profile', { title: 'My Overridden Title', path: '/profile' });
  trackPageView(title, properties = {}) {
    this.analytics.client.page(title, properties);
  }

  // For more reference on the options argument, please visit:
  // https://segment.com/docs/sources/website/analytics.js/#track
  //
  // This can be used to track general events that doesn't necessarily
  // involve a user clicking a link, which is covered by trackLink.
  //
  // Usage example:
  //
  //   import EventTracker from 'lib/eventTracker';
  //
  //   tracker.track('Open the green tooltip');
  track(title, options) {
    this.analytics.client.track(title, options);
  }

  // Install track link for HTMLElements which match selectors.
  // This will require that you add some extra configuration attributes
  // to the links (or other elements) you want to track in their datasets.
  // Usage example:
  //
  // JS:
  //   const tracker = new EventTracker();
  //   tracker.linkTracker('a[data-track-event]');
  //
  // HTML:
  //   <a href="/profile" data-track-event="Click on Profile link" data-track-user-id="1">Profile</a>
  linkTracker(selectors, title, extraProperties = {}) {
    document.querySelectorAll(selectors).forEach((node) => {
      this.trackLink(node, title || node.dataset.trackEvent, (element) =>
        Object.assign(this.collectProperties(element.dataset), extraProperties),
      );
    });
  }

  // Usage example:
  //
  // JS:
  //   const tracker = new EventTracker();
  //   tracker.formTracker('form[data-track-event-submit]');
  //
  // HTML:
  //   <form data-track-event-submit="Submit the form"> ... </form>
  formTracker(selectors, title, defaultProperties = {}) {
    document.querySelectorAll(selectors).forEach((node) => {
      this.trackForm(node, title || node.dataset.trackEventSubmit, (element) => {
        return Object.assign(defaultProperties, this.collectFormData(element));
      });
    });
  }

  // Collect data from a Form fields
  collectFormData(form) {
    const formData = new FormData(form);
    const data = {};
    for (const [key, value] of formData.entries()) {
      data[key] = value;
    }
    return data;
  }

  // Collect tracking properties from an HTMLElement dataset
  collectProperties(dataset, prefix = 'track') {
    const properties = {};
    for (const key in dataset) {
      if (key.startsWith(prefix)) {
        properties[key.slice(prefix.length)] = dataset[key];
      }
    }
    return properties;
  }
}

export default EventTracker;
