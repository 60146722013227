import React, { useState } from "react";
import PropTypes from "prop-types";
import { isMobile } from "lib/utils";

const itemsPerPage = isMobile() ? 1 : 3;

const NextControl = ({ currentPage, totalItems, onClick }) => {
  const shouldHideNext = currentPage * itemsPerPage >= totalItems;

  if (shouldHideNext) {
    return null;
  }

  return (
    <div className="carousel-controls" onClick={onClick}>
      <span className="next-control" />
      <p className="next-control-name">More</p>
    </div>
  );
};

const PrevControl = ({ currentPage, onClick }) => {
  const shouldHidePrev = currentPage === 1;
  if (shouldHidePrev) {
    return null;
  }

  return <span className="prev-control" onClick={onClick} />;
};

function CarouselDates({ items, itemMapperFn }) {
  const [currentPage, setCurrentPage] = useState(1);

  const goPrevPage = () => {
    const updatedCurrentPage = currentPage - 1;
    setCurrentPage(updatedCurrentPage);
  };

  const goNextPage = () => {
    const updatedCurrentPage = currentPage + 1;
    setCurrentPage(updatedCurrentPage);
  };

  const getDisplayedItems = () => {
    const startItemIndex = (currentPage - 1) * itemsPerPage;
    const endItemIndex = currentPage * itemsPerPage;

    return items.slice(startItemIndex, endItemIndex);
  };

  const displayedItems = getDisplayedItems();

  return (
    <>
      <div className="carousel-dates">
        {displayedItems.map(itemMapperFn)}
        <PrevControl onClick={goPrevPage} currentPage={currentPage} />
        <NextControl
          onClick={goNextPage}
          currentPage={currentPage}
          totalItems={items.length}
        />
      </div>
    </>
  );
}

NextControl.propTypes = {
  onClick: PropTypes.func,
  currentPage: PropTypes.number,
  totalItems: PropTypes.number,
};

PrevControl.propTypes = {
  onClick: PropTypes.func,
  currentPage: PropTypes.number,
  totalItems: PropTypes.number,
};

CarouselDates.propTypes = {
  itemMapperFn: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CarouselDates;
