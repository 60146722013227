import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ConfirmSessionButton = ({ selectedTrainingSession, selectedTrainingType, confirmTrainingSessionsFn }) => {
  if (!selectedTrainingType) {
    return null;
  }

  const shouldDisableButton = selectedTrainingSession === null;
  const confirmSessionButtonClassnames = classNames(
    'button',
    'secondary-button',
    'secondary-button--large',
    'select-training-sessions-confirm',
    { 'secondary-button--disabled': shouldDisableButton },
  );

  return (
    <footer className="select-training-sessions-action-bar-wrapper">
      <div className="container select-training-sessions-action-bar">
        <div className={confirmSessionButtonClassnames} onClick={confirmTrainingSessionsFn}>
          Continue
        </div>
      </div>
    </footer>
  );
};

ConfirmSessionButton.propTypes = {
  selectedTrainingType: PropTypes.string,
  selectedTrainingSession: PropTypes.object,
};

export default ConfirmSessionButton;
