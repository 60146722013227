import React from 'react';
import PropTypes from 'prop-types';

const LocationDistance = ({ distance }) => {
  if (distance === null) {
    return null;
  }

  return <small className="location-distance">{distance.toFixed(2)} miles away</small>;
};

export default LocationDistance;

LocationDistance.propTypes = {
  distance: PropTypes.number,
};
