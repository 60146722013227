import React from 'react';
import PropTypes from 'prop-types';
import VolunteerOpportunityType from './VolunteerOpportunityType';

const VolunteerOpportunityTypes = ({
  callCenters,
  fieldPrograms,
  selectedOpportunityType,
  selectOpportunityTypeFn,
}) => {
  return (
    <div className="select-shifts-types">
      <VolunteerOpportunityType
        opportunityType="callCenters"
        opportunities={callCenters}
        selectedOpportunityType={selectedOpportunityType}
        selectOpportunityTypeFn={selectOpportunityTypeFn}
      />
      <VolunteerOpportunityType
        opportunityType="fieldPrograms"
        opportunities={fieldPrograms}
        selectedOpportunityType={selectedOpportunityType}
        selectOpportunityTypeFn={selectOpportunityTypeFn}
      />
    </div>
  );
};

VolunteerOpportunityTypes.propTypes = {
  callCenters: PropTypes.array.isRequired,
  fieldPrograms: PropTypes.array.isRequired,
  selectedOpportunityType: PropTypes.string,
  selectOpportunityTypeFn: PropTypes.func.isRequired,
};

export default VolunteerOpportunityTypes;
