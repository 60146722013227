import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ConfirmProfileButton = ({ isValid, isSubmitting, handleSubmitFn, formConfig: { submitButtonLabel } }) => {
  const confirmButtonDisabled = !isValid || isSubmitting;
  const confirmProfileButtonClassnames = classNames(
    'button',
    'secondary-button',
    'secondary-button--large',
    'confirm-profile-button',
    {
      'secondary-button--disabled': confirmButtonDisabled,
    },
  );

  const submitLabel = isSubmitting ? 'Saving...' : submitButtonLabel;

  return (
    <footer className="confirm-profile-footer">
      <div className="container confirm-profile-action-bar">
        <button
          onClick={handleSubmitFn}
          disabled={confirmButtonDisabled}
          tabIndex="0"
          className={confirmProfileButtonClassnames}
        >
          {submitLabel}
        </button>
      </div>
    </footer>
  );
};

ConfirmProfileButton.propTypes = {
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleSubmitFn: PropTypes.func.isRequired,
  formConfig: PropTypes.object.isRequired,
};

export default ConfirmProfileButton;
