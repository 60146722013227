import React from "react";
import * as R from "ramda";

const queryCSRFToken = () => {
  const metaTag = document.querySelector("meta[name=csrf-token]");
  return metaTag ? metaTag.content : null;
};

export const getCSRFToken = R.once(queryCSRFToken);

const CSRFToken = () => (
  <input
    value={getCSRFToken() || ""}
    name="authenticity_token"
    hidden={true}
    readOnly={true}
  />
);

export default CSRFToken;
