import React from "react";
import PropTypes from "prop-types";
import VolunteeringDetailItem from "./VolunteeringDetailItem";

const VolunteeringDetailItems = ({
  items,
  volunteeringDetailType,
  addEventToCalendarActionRequired,
  viewMapActionRequired,
  openCancelShiftModalFn,
}) => (
  <div className="volunteering-detail-items">
    {items.map(item => (
      <VolunteeringDetailItem
        addEventToCalendarActionRequired={addEventToCalendarActionRequired}
        item={item}
        key={item.id}
        openCancelShiftModalFn={openCancelShiftModalFn}
        viewMapActionRequired={viewMapActionRequired}
        volunteeringDetailType={volunteeringDetailType}
      />
    ))}
  </div>
);

export default VolunteeringDetailItems;

VolunteeringDetailItems.propTypes = {
  volunteeringDetailType: PropTypes.string.isRequired,
  addEventToCalendarActionRequired: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  openCancelShiftModalFn: PropTypes.func,
  viewMapActionRequired: PropTypes.bool,
};

VolunteeringDetailItems.defaultProps = {
  addEventToCalendarActionRequired: true,
  viewMapActionRequired: true,
};
