import React from "react";
import ReactDOM from "react-dom";

import CompleteProfileForm from "components/CompleteProfileForm";

import { csrfFetch, railsErrorsToFormikErrors } from "lib/utils";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.querySelector("#root_volunteering_complete_profile");
  if (node) {
    const staticDataNode = document.querySelector(
      "#volunteer-form-static-data",
    );

    const volunteer = JSON.parse(node.dataset.volunteer).data;
    const booking = JSON.parse(node.dataset.booking).data;
    const formConfig = JSON.parse(node.dataset.formConfig);
    const {
      areasOfInterest,
      areasOfPractice,
      languages,
      legalSkills,
      legalTypes,
      referredBy,
      states,
      willingToTravel,
      yearsOfExperience,
    } = JSON.parse(staticDataNode.dataset.formStaticData);

    const submitFn = (data, updateErrors, setSubmitting) => {
      csrfFetch(formConfig.url, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(response => {
        response.json().then(data => {
          if (data.status == "ok") {
            document.location.href = data.booking.links.next;
          } else {
            updateErrors(railsErrorsToFormikErrors(data.errors));
            setSubmitting(false);
            window.scrollTo(0, 0);
          }
        });
      });
    };

    ReactDOM.render(
      <CompleteProfileForm
        areasOfInterest={areasOfInterest}
        areasOfPractice={areasOfPractice}
        booking={booking}
        formConfig={formConfig}
        languages={languages}
        legalSkills={legalSkills}
        legalTypes={legalTypes}
        referredBy={referredBy}
        states={states}
        submitFn={submitFn}
        willingToTravel={willingToTravel}
        yearsOfExperience={yearsOfExperience}
        volunteer={volunteer}
      />,
      node,
    );
  }
});
