import React from 'react';
import PropTypes from 'prop-types';

import CarouselDates from 'components/shared/CarouselDates';
import AvailableTimeSlots from 'components/shared/AvailableTimeSlots';

const AvailableDates = ({
  sessions,
  selectedTrainingSession,
  updateSelectedSessionFn,
  seatsAvailabilityCheckRequired,
}) => {
  const selectedSlots = selectedTrainingSession ? [selectedTrainingSession] : [];
  const sessionItemMapperFn = (session) => {
    return (
      <AvailableTimeSlots
        key={session.date}
        slotDate={session.date}
        availableTimes={session.availableTimes}
        selectedSlots={selectedSlots}
        updateSelectedSlotFn={updateSelectedSessionFn}
        seatsAvailabilityCheckRequired={seatsAvailabilityCheckRequired}
      />
    );
  };

  return (
    <div className="select-training-sessions-available-dates">
      <CarouselDates items={sessions} itemMapperFn={sessionItemMapperFn} />
    </div>
  );
};

AvailableDates.propTypes = {
  sessions: PropTypes.array.isRequired,
  selectedTrainingSession: PropTypes.object,
  updateSelectedSessionFn: PropTypes.func.isRequired,
};

export default AvailableDates;
