import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ConfirmShiftsButton = ({ selectedOpportunityType, numberOfShifts, openConfirmShiftsModalFn }) => {
  const actionBarWrapperClassnames = classNames('select-shifts-action-bar-wrapper', {
    'select-shifts-action-bar-wrapper--no-selection': numberOfShifts === 0,
  });

  const confirmShiftsButtonClassnames = classNames(
    'button',
    'secondary-button',
    'secondary-button--large',
    'select-shifts-confirm',
    { 'secondary-button--disabled': numberOfShifts === 0 },
  );

  if (selectedOpportunityType) {
    return (
      <footer className={actionBarWrapperClassnames}>
        <div className="container select-shifts-action-bar">
          <div
            tabIndex="0"
            className={confirmShiftsButtonClassnames}
            onClick={() => {
              if (numberOfShifts > 0) {
                openConfirmShiftsModalFn();
              }
            }}
          >
            {numberOfShifts !== 0 && <span className="button-helper">{numberOfShifts}</span>}
            Confirm Shifts
          </div>
        </div>
      </footer>
    );
  }

  return null;
};

ConfirmShiftsButton.propTypes = {
  selectedOpportunityType: PropTypes.string,
  numberOfShifts: PropTypes.number,
  openConfirmShiftsModalFn: PropTypes.func.isRequired,
};

export default ConfirmShiftsButton;
