import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import * as yup from "yup";
import * as R from "ramda";

import CSRFToken from "lib/forms/CSRFToken";
import SelectField from "lib/forms/SelectField";
import Field from "lib/forms/Field";

const validationSchema = yup.object().shape({
  booking: yup.object().shape({
    first_name: yup
      .string()
      .nullable()
      .required("First Name is required"),
    email: yup
      .string()
      .nullable()
      .required("Email is required")
      .email("Must be a valid email address"),
    legal_type: yup
      .string()
      .nullable()
      .required("Legal type is required"),
  }),
});

const LegalTypeSelect = ({
  options,
  setFieldValue,
  setFieldTouched,
  ...formikProps
}) => {
  const { values } = formikProps;

  return (
    <div className="legal-type-select">
      <SelectField
        label="What is your Legal Experience?"
        name="booking[legal_type]"
        options={options}
        {...formikProps}
        setFieldValueFn={setFieldValue}
        setFieldTouchedFn={setFieldTouched}
      />
      <input
        type="hidden"
        name="booking[legal_type]"
        value={values.booking.legal_type}
      />
      {values.booking.legal_type === "Lawyer" && (
        <p className="signup-form__disclaimer">
          By selecting "Lawyer" you confirm you are licensed to practice law, in
          good standing, in at least one state.
        </p>
      )}
    </div>
  );
};

const Form = ({ actionUrl, legalTypeOptions, ...formikProps }) => {
  const commonFormikProps = R.pick(
    ["values", "touched", "errors"],
    formikProps,
  );

  const { setFieldTouched, setFieldValue, isValid } = formikProps;

  return (
    <form action={actionUrl} method="post" className="missing-volunteer-form">
      <CSRFToken />
      <Field
        name="booking[first_name]"
        placeholder="First Name"
        {...commonFormikProps}
      />
      <Field
        name="booking[email]"
        placeholder="Email Address"
        {...commonFormikProps}
      />

      <LegalTypeSelect
        options={legalTypeOptions}
        {...commonFormikProps}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />

      <button
        type="submit"
        className="primary-button primary-button--fixed primary-button--landing"
        disabled={!isValid}
      >
        Get Started
      </button>
    </form>
  );
};

const MissingVolunteerForm = withFormik({
  mapPropsToValues: () => ({
    booking: { first_name: "", email: "", legal_type: "" },
  }),
  validationSchema,
})(Form);

MissingVolunteerForm.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  legalTypeOptions: PropTypes.arrayOf(PropTypes.object),
};

export default MissingVolunteerForm;
