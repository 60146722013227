import React from "react";
import PropTypes from "prop-types";

import Tooltip from "components/shared/Tooltip";

import Field from "lib/forms/Field";
import MultiSelectField from "lib/forms/MultiSelectField";
import SelectField from "lib/forms/SelectField";
import RadioField from "lib/forms/RadioField";

const BasicInformationForm = props => {
  const { languages, referredBy, willingToTravel } = props;
  return (
    <section className="complete-profile-form-section">
      <h2 className="complete-profile-form__section-heading">
        Basic Information
      </h2>

      <Field
        label="First name"
        name="volunteer.firstName"
        isRequired
        {...props}
      />
      <Field
        label="Last name"
        name="volunteer.lastName"
        isRequired
        {...props}
      />
      <Field
        label="Email Address"
        name="volunteer.email"
        type="email"
        isRequired
        {...props}
      />

      <Field
        label="Home Zip Code"
        name="volunteer.volunteerProfile.zipCode"
        isRequired
        {...props}
      />

      <Field
        label="Cell Phone Number"
        name="volunteer.volunteerProfile.mobilePhone"
        isRequired
        {...props}
      />
      <Field
        label="Work Phone Number"
        name="volunteer.volunteerProfile.workPhone"
        {...props}
      />

      <MultiSelectField
        label="Fluent Language(s)"
        name="volunteer.volunteerProfile.languages"
        options={languages}
        isRequired
        {...props}
      />

      <MultiSelectField
        label="Referred By"
        name="volunteer.volunteerProfile.referredBy"
        options={referredBy}
        isRequired
        {...props}
      />

      <RadioField
        label="Do you have a car?"
        name="volunteer.volunteerProfile.hasCar"
        className="has-car"
        options={{ Yes: true, No: false }}
        tooltip={() => (
          <Tooltip>
            When applicable, this helps coordinators distribute volunteers to
            different locations appropriately.
          </Tooltip>
        )}
        {...props}
      />

      <RadioField
        label="Have you volunteered for Election Protection in the past?"
        name="volunteer.volunteerProfile.hasVolunteered"
        className="has-volunteered"
        options={{ Yes: true, No: false }}
        isRequired
        {...props}
      />

      <SelectField
        label="How far are you willing to travel?"
        name="volunteer.volunteerProfile.willingToTravel"
        options={willingToTravel}
        isRequired
        {...props}
      />
    </section>
  );
};

BasicInformationForm.propTypes = {
  errors: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  referredBy: PropTypes.array.isRequired,
  setFieldTouchedFn: PropTypes.func.isRequired,
  setFieldValueFn: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  willingToTravel: PropTypes.array.isRequired,
};

export default BasicInformationForm;
