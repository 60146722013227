import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

class MultiSelectInput extends Component {
  handleChange = (values) => {
    const { id, onChange } = this.props;
    onChange(id, values.map((item) => item.value));
  };

  handleBlur = () => {
    const { id, onBlur } = this.props;
    onBlur(id, true);
  };

  render() {
    const { id, options, value } = this.props;
    return (
      <ReactSelect
        id={id}
        options={options}
        multi={true}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        value={value}
        joinValues
      />
    );
  }
}

MultiSelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
};

export default MultiSelectInput;
