import React from 'react';
import PropTypes from 'prop-types';

import Modal, { ModalFooter } from 'lib/modal';

import ConfirmShiftsLocations from './ConfirmShiftsLocations';

function filterLocationsShifts(availableShifts, selectedShifts) {
  return availableShifts.filter((location) => hasShiftsCoincidences(location, selectedShifts));
}

function hasShiftsCoincidences({ attributes: { id: currentVolunteerOppId } }, selectedShifts) {
  return !!selectedShifts.find(({ volunteerOpportunityId }) => {
    return currentVolunteerOppId === volunteerOpportunityId;
  });
}

const ConfirmShiftsModal = ({
  availableShifts,
  closeModalFn,
  confirmShiftsFn,
  modalIsOpen,
  selectedShifts,
  updateSelectedShiftsFn,
}) => {
  const filteredLocationsShifts = filterLocationsShifts(availableShifts, selectedShifts);

  return (
    <Modal
      className="confirm-shifts-modal"
      isOpen={modalIsOpen}
      closeModalFn={closeModalFn}
      heading="Confirm Shifts"
      subheading={`${selectedShifts.length} shift(s) selected`}
    >
      <ConfirmShiftsLocations
        locations={filteredLocationsShifts}
        selectedShifts={selectedShifts}
        updateSelectedShiftsFn={updateSelectedShiftsFn}
      />
      <ModalFooter>
        <div
          tabIndex="0"
          className="button secondary-button secondary-button--large confirm-shifts-button"
          onClick={confirmShiftsFn}
        >
          Continue
        </div>
      </ModalFooter>
    </Modal>
  );
};

ConfirmShiftsModal.propTypes = {
  availableShifts: PropTypes.array.isRequired,
  closeModalFn: PropTypes.func.isRequired,
  confirmShiftsFn: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  scrollPosition: PropTypes.number,
  selectedShifts: PropTypes.array.isRequired,
  updateSelectedShiftsFn: PropTypes.func.isRequired,
};

export default ConfirmShiftsModal;
