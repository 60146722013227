import React from "react";
import Field from "./Field";
import SelectInput from "./inputs/SelectInput";

const SelectField = props => {
  const {
    name,
    label,
    tooltip,
    values,
    touched,
    errors,
    options,
    setFieldValueFn,
    setFieldTouchedFn,
    disabled,
    isRequired,
  } = props;
  return (
    <Field
      label={label}
      name={name}
      tooltip={tooltip}
      inputId={name}
      values={values}
      touched={touched}
      errors={errors}
      isRequired={isRequired}
      render={({ name, value }) => (
        <SelectInput
          id={name}
          value={value}
          className="complete-profile-form__field"
          onChange={setFieldValueFn}
          onBlur={setFieldTouchedFn}
          options={options}
          disabled={disabled}
        />
      )}
    />
  );
};

export default SelectField;
