// Entry point for the build script in your package.json
import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";

import "packs/home";
import "packs/layouts/shared";
import "packs/volunteering";
import "packs/volunteers";

import Rails from "@rails/ujs";
Rails.start();
